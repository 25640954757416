@charset "UTF-8";
/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --de-dark-blue: #08496f !important;
  --bs-blue: #0e7bba !important;
  --bs-primary-rgb: 14, 123, 186 !important;
  --de-dark-blue-rgb: 8, 73, 111 !important;
  --bs-primary: #0e7bba !important;
  --bs-link-color: #0e7bba !important;
  --bs-body-font-family: "Source Sans 3", sans-serif !important;
  --bs-pagination-active-bg: #0e7bba !important;
  --bs-pagination-active-border-color: #0e7bba !important;
  --bs-btn-disabled-bg: #0e7bba !important;
  --bs-btn-disabled-border-color: #0e7bba !important;
  --bs-white: #fff !important;
  --bs-clarivate-font: "Regular Clarivate", sans-serif !important;
  --c-success: #018760 !important;
  --c-success-dark: #005139 !important;
  --c-success-light: #E5F3EF !important;
  --bs-link-hover-color: #08496f !important;
}

.btn-primary {
  --bs-blue: #0e7bba !important;
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #0e7bba !important;
  --bs-btn-border-color: #0e7bba !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #0c6ea7 !important;
  --bs-btn-hover-border-color: #095682 !important;
  --bs-btn-focus-shadow-rgb: 46, 147, 179 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #0b6294 !important;
  --bs-btn-active-border-color: #095682 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
}

.btn-outline-primary {
  --bs-btn-color: #0e7bba;
  --bs-btn-border-color: #0e7bba;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e7bba;
  --bs-btn-hover-border-color: #0e7bba;
  --bs-btn-focus-shadow-rgb: 9, 128, 165;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e7bba;
  --bs-btn-active-border-color: #0e7bba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0e7bba;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0e7bba;
  --bs-gradient: none;
}

body {
  font-family: var(--bs-body-font-family);
  background-color: #eeeeee !important;
}

.serif {
  font-family: "Source Serif 4";
}

.navbar {
  --bs-navbar-color: #fff;
}

.nav-link:focus, .form-switch .form-check-input:focus, 
.form-control:focus, .btn:focus, .btn-primary:focus { 
  box-shadow: 0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0e7bba !important; 
}

main  {
  position: relative;
  min-height: 100vh;
}

.content {
  padding-bottom: 225px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  
}

.de-footer {
  max-width: 100%;
}
@media (min-width: 768px) {
  .de-footer {
    max-width: 900px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 900px !important;
  }
}
 .de-header {
   background-color: #0e7bba;
 }

@media (min-width: 768px) {
  .de-accordion-header-md {
    margin-left: calc(100% - 6rem);
  }

  .mw-118 {
    max-width: 118px !important;
  }
}

#header {
  background-color: var(--bs-blue);
}

.navbar-collapse {
  background-color: var(--bs-blue) !important;
}

@media (min-width: 992px) {
  .navbar-collapse {
    background-color: var(--bs-blue) !important;
  }
}

.page-link.active, .active > .page-link {
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.nav-list {
  list-style-type: disc;
}

.navbar-light .navbar-toggler,
.navbar-light .navbar-nav .nav-link,
.dropdown-toggle::after {
  color: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
}

.nav-link.active,
.nav.nav-link-gray .nav-link,
.nav.nav-link-gray .nav-link.active,
.nav.nav-link-gray .nav-link:hover,
.nav-link.active .nav-link-svg path,
.nav-item.show .nav-link-svg path {
  fill: #fff;
}

@media (max-width: 991.98px) {
.navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
    min-width: 90% !important;
  }
}

.navbar .dropdown-menu {
  border-top-color: var(--de-dark-blue) !important;
}

.de-dark-blue {
  background-color: var(--de-dark-blue) !important;
}

.go-to {
  opacity: 1 !important;
  background-color: rgba(14, 123, 186, 0.1);
  color: #0e7bba;
  border: 1px solid #0e7bba;
}

.go-to:hover, .go-to:focus:hover {
  background-color: var(--bs-blue);
}
 .help {
   color: #555;
 }

.de-row {
  flex-shrink: initial;
}

.hs-mega-menu {
  padding: 0 !important;
}
.hs-mega-menu .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0.5rem;
}

.clarivate {
  font-family: var(--bs-clarivate-font);
}

.mt-search {
  margin-top: 42px !important;
}

.pq-nav {
   font-size: 15px;
}

.de-nav {
  font-family: var(--bs-clarivate-font);
}

.btn.btn-link:hover {
  border: 1px solid var(--de-dark-blue) !important;
  color: var(--de-dark-blue);
}

.form-control {
  border: 0.0625rem solid rgba(52, 52, 52, 0.5);
}

.form-control:focus {
  border: 0.0625rem solid rgba(52, 52, 52, 0.75);
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}

.form-check-input:checked {
  background-color: var(--bs-primary) !important;
}

.card {
  --bs-card-spacer-y:  1.75rem !important;
  --bs-card-spacer-x:  1.75rem !important;
  --bs-card-cap-padding-y: 1.25rem !important;
  --bs-card-cap-padding-x: 1.75rem !important;
}

.form-switch .form-check-input {
  background-color: #8e8f90;
}

.page-link:hover {
  color: var(--de-dark-blue);
}

.dropdown-toggle::after {
  display: none;
}

.page-item.active > .page-link:hover {
  color: var(--bs-white);
}

.content-space {
  padding-top: 7rem !important;
  /*padding-bottom: 7rem !important;*/
}

.alert-chars, .bg-soft-info {
  background-color: #e6f1f8 !important;
  color: var(--de-dark-blue) !important;
}

.bi-caret-down-fill.ms-2 {
  font-size: 14px;
}

.hs-has-mega-menu.nav-item:hover {
  text-decoration-line: underline;
  color: var(--bs-white);
}

.bg-soft-success {
  background-color: var(--c-success-light) !important;
}
.text-success {
  color: var(--c-success) !important;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    background-color: var(--bs-white) !important;
  }

  .infoHover {
    display: none;
  }

  .card-footer {
    padding: 0px !important;
    white-space: nowrap;
  }

  .px-3 {
    padding-bottom: 50px;
  }
}


#liveToast {
  background-color: rgba(255, 236, 203, 0.95) !important;
  color: var(--bs-gray-900) !important;
  top: 4px;
  bottom: revert;
  right: 4px;
  --bs-toast-max-width: 70%;

}

@media (min-width: 576px) {
  #liveToast {
    top: revert;
    bottom: 100px;
    right: 20px;
    --bs-toast-max-width: 350px;
  }
}

::selection {
  color: #0B6294;
  background-color: rgba(11, 98, 148, 0.1);
}

.card table tr th:not(:last-child) {
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.card table thead tr th {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1) !important;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: var(--de-dark-blue) !important;
  border-color: var(--de-dark-blue) !important;
  box-shadow: 0 4px 11px rgba(8, 73, 111, 0.35);
}

.alert-danger {
  border: 1px solid #C52A4F;
  color: #891D37;
  background-color: #F9E9ED;
}

.alert-info {
  border: 1px solid #bce8f1;
  color: #31708f;
  background-color: #d9edf7;
}

.clarivate-logo {
    width: 100% !important;
    height: auto !important;
}
  @media (min-width: 1200px) {
    .clarivate-logo {
      width: calc(1.6rem + 0.6vw);
      height: calc(1.6rem + 0.6vw);
    }
  }


.modal {
  --bs-modal-width: 750px;
}

.fw-500 {
  font-weight: 500 !important;
}

#helpMegaMenu, #pricingMegaMenu {
  padding-left: 1rem;
  padding-right: 1rem;
}

.go-to {
  cursor: pointer;
}

.greySection {
  color: #5E6E7D;
}

.text-muted {
  color: #697586 !important
}

.ngx-pagination {
  padding-left: 0px !important;
}

/* Safari hack */
.fw-semibold {
  font-weight: 599 !important;
}