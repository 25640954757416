/* You can add global styles to this file, and also import other style files */

@import "src/assets/styles/theme.css";
@import "src/assets/styles/custom.css";
@import "node_modules/bootstrap-icons/font/bootstrap-icons.css";

/* -------------------------------- Source-Sans ------------------------------- */
@font-face {
    font-family: 'Source Sans 3';
    src: url('./assets/fonts/Source-Sans/SourceSans3-Regular.ttf.woff2') format('woff2'),
    url('./assets/fonts/Source-Sans/SourceSans3-Regular.ttf.woff') format('woff');
}

/* -------------------------------- Source-Serif ------------------------------ */
@font-face {
    font-family: 'Source Serif 4';
    src: url('./assets/fonts/Source-Serif/SourceSerif4-Regular.ttf.woff2') format('woff2'),
    url('./assets/fonts/Source-Serif/SourceSerif4-Regular.ttf.woff') format('woff');
}
/*
"./node_modules/proquest-uikit/dist/css/uxframework-blue.min.css",
"./node_modules/bootstrap-icons/font/bootstrap-icons.css",
*/
html, body { height: 100%; }

