@charset "UTF-8";
/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #006C7B;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #ed4c78;
  --bs-orange: #fd7e14;
  --bs-yellow: #f5ca99;
  --bs-green: #198754;
  --bs-teal: #00c9a7;
  --bs-cyan: #09a5be;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #8c98a4;
  --bs-gray-dark: #71869d;
  --bs-gray-100: #f7faff;
  --bs-gray-200: #f8fafd;
  --bs-gray-300: #e7eaf3;
  --bs-gray-400: #bdc5d1;
  --bs-gray-500: #97a4af;
  --bs-gray-600: #8c98a4;
  --bs-gray-700: #677788;
  --bs-gray-800: #71869d;
  --bs-gray-900: #1e2022;
  --bs-primary: #006C7B;
  --bs-secondary: #71869d;
  --bs-success: #00c9a7;
  --bs-info: #09a5be;
  --bs-warning: #f5ca99;
  --bs-danger: #ed4c78;
  --bs-light: #f7faff;
  --bs-dark: #21325b;
  --bs-primary-rgb: 0, 108, 123;
  --bs-secondary-rgb: 113, 134, 157;
  --bs-success-rgb: 0, 201, 167;
  --bs-info-rgb: 9, 165, 190;
  --bs-warning-rgb: 245, 202, 153;
  --bs-danger-rgb: 237, 76, 120;
  --bs-light-rgb: 247, 250, 255;
  --bs-dark-rgb: 33, 50, 91;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 103, 119, 136;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Inter, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #677788;
  --bs-body-bg: #fff;
  --bs-border-width: 0.0625rem;
  --bs-border-style: solid;
  --bs-border-color: rgba(33, 50, 91, 0.1);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.5rem;
  --bs-border-radius-sm: 0.3125rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #006C7B;
  --bs-link-hover-color: #004d57;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fdf4eb;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: rgba(33, 50, 91, 0.1);
  border: 0;
  border-top: 0.0625rem solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1e2022;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.3125rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 1em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 1em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 1em;
  color: #1e2022;
  background-color: #e7eaf3;
  border-radius: 0.3125rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
}

th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.25625rem + 0.075vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.3125rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.25rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #1e2022;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid var(--bs-border-color);
  border-radius: 0.5rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #8c98a4;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 4.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3rem;
  }
  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 4.5rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: rgba(33, 50, 91, 0.1);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #f7faff;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(231, 234, 243, 0.4);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0.0625rem;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 0.125rem solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0.0625rem 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 0.0625rem;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cce2e5;
  --bs-table-border-color: #b8cbce;
  --bs-table-striped-bg: #c2d7da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8cbce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd1d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e3e7eb;
  --bs-table-border-color: #ccd0d4;
  --bs-table-striped-bg: #d8dbdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ccd0d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d2d6d9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #ccf4ed;
  --bs-table-border-color: #b8dcd5;
  --bs-table-striped-bg: #c2e8e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8dcd5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde2db;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ceedf2;
  --bs-table-border-color: #b9d5da;
  --bs-table-striped-bg: #c4e1e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9d5da;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfdbe0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fdf4eb;
  --bs-table-border-color: #e4dcd4;
  --bs-table-striped-bg: #f0e8df;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4dcd4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eae2d9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fbdbe4;
  --bs-table-border-color: #e2c5cd;
  --bs-table-striped-bg: #eed0d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2c5cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8cbd3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f7faff;
  --bs-table-border-color: #dee1e6;
  --bs-table-striped-bg: #ebeef2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dee1e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e7ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #21325b;
  --bs-table-border-color: #37476b;
  --bs-table-striped-bg: #2c3c63;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #37476b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #324167;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #1e2022;
}

.col-form-label {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #1e2022;
}

.col-form-label-lg {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 1rem;
  font-size: 0.875em;
  color: #8c98a4;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  appearance: none;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1e2022;
  background-color: #fff;
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #8c98a4;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f8fafd;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.6125rem 1rem;
  margin: -0.6125rem -1rem;
  margin-inline-end: 1rem;
  color: #1e2022;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6125rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #677788;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.125rem);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.625rem);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.35rem);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1.125rem);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.625rem);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.35rem);
  padding: 0.6125rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.3125rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.3125rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 1.125rem);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.625rem);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.6125rem 3rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2371869d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f8fafd;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1e2022;
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(33, 50, 91, 0.1);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: 100%;
}
.form-check-input:focus {
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form-check-input:checked {
  background-color: #006C7B;
  border-color: #006C7B;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #006C7B;
  border-color: #006C7B;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  color: #677788;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #006C7B;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3d3d7;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #006C7B;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3d3d7;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #97a4af;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #97a4af;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 3.625rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 0.0625rem solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 0.0625rem 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6125rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8c98a4;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.3125rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -0.0625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00c9a7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #00c9a7;
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c9a7;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00c9a7;
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00c9a7;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00c9a7;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00c9a7;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed4c78;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #ed4c78;
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ed4c78;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ed4c78;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ed4c78;
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ed4c78;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ed4c78;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ed4c78;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.6125rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #677788;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0.0625rem;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.3125rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #006C7B;
  --bs-btn-border-color: #006C7B;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #005c69;
  --bs-btn-hover-border-color: #005662;
  --bs-btn-focus-shadow-rgb: 38, 130, 143;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005662;
  --bs-btn-active-border-color: #00515c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #006C7B;
  --bs-btn-disabled-border-color: #006C7B;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #71869d;
  --bs-btn-border-color: #71869d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #607285;
  --bs-btn-hover-border-color: #5a6b7e;
  --bs-btn-focus-shadow-rgb: 134, 152, 172;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a6b7e;
  --bs-btn-active-border-color: #556576;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #71869d;
  --bs-btn-disabled-border-color: #71869d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00c9a7;
  --bs-btn-border-color: #00c9a7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00ab8e;
  --bs-btn-hover-border-color: #00a186;
  --bs-btn-focus-shadow-rgb: 38, 209, 180;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00a186;
  --bs-btn-active-border-color: #00977d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00c9a7;
  --bs-btn-disabled-border-color: #00c9a7;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #09a5be;
  --bs-btn-border-color: #09a5be;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #088ca2;
  --bs-btn-hover-border-color: #078498;
  --bs-btn-focus-shadow-rgb: 46, 179, 200;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #078498;
  --bs-btn-active-border-color: #077c8f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #09a5be;
  --bs-btn-disabled-border-color: #09a5be;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #f5ca99;
  --bs-btn-border-color: #f5ca99;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7d2a8;
  --bs-btn-hover-border-color: #f6cfa3;
  --bs-btn-focus-shadow-rgb: 208, 172, 130;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7d5ad;
  --bs-btn-active-border-color: #f6cfa3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f5ca99;
  --bs-btn-disabled-border-color: #f5ca99;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ed4c78;
  --bs-btn-border-color: #ed4c78;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c94166;
  --bs-btn-hover-border-color: #be3d60;
  --bs-btn-focus-shadow-rgb: 240, 103, 140;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #be3d60;
  --bs-btn-active-border-color: #b2395a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ed4c78;
  --bs-btn-disabled-border-color: #ed4c78;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f7faff;
  --bs-btn-border-color: #f7faff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d2d5d9;
  --bs-btn-hover-border-color: #c6c8cc;
  --bs-btn-focus-shadow-rgb: 210, 213, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c8cc;
  --bs-btn-active-border-color: #b9bcbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f7faff;
  --bs-btn-disabled-border-color: #f7faff;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #21325b;
  --bs-btn-border-color: #21325b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #425174;
  --bs-btn-hover-border-color: #37476b;
  --bs-btn-focus-shadow-rgb: 66, 81, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5b7c;
  --bs-btn-active-border-color: #37476b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #21325b;
  --bs-btn-disabled-border-color: #21325b;
}

.btn-outline-primary {
  --bs-btn-color: #006C7B;
  --bs-btn-border-color: #006C7B;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #006C7B;
  --bs-btn-hover-border-color: #006C7B;
  --bs-btn-focus-shadow-rgb: 0, 108, 123;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #006C7B;
  --bs-btn-active-border-color: #006C7B;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #006C7B;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #006C7B;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #71869d;
  --bs-btn-border-color: #71869d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #71869d;
  --bs-btn-hover-border-color: #71869d;
  --bs-btn-focus-shadow-rgb: 113, 134, 157;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #71869d;
  --bs-btn-active-border-color: #71869d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #71869d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #71869d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #00c9a7;
  --bs-btn-border-color: #00c9a7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00c9a7;
  --bs-btn-hover-border-color: #00c9a7;
  --bs-btn-focus-shadow-rgb: 0, 201, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00c9a7;
  --bs-btn-active-border-color: #00c9a7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00c9a7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00c9a7;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #09a5be;
  --bs-btn-border-color: #09a5be;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #09a5be;
  --bs-btn-hover-border-color: #09a5be;
  --bs-btn-focus-shadow-rgb: 9, 165, 190;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #09a5be;
  --bs-btn-active-border-color: #09a5be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #09a5be;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #09a5be;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #f5ca99;
  --bs-btn-border-color: #f5ca99;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f5ca99;
  --bs-btn-hover-border-color: #f5ca99;
  --bs-btn-focus-shadow-rgb: 245, 202, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5ca99;
  --bs-btn-active-border-color: #f5ca99;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5ca99;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5ca99;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ed4c78;
  --bs-btn-border-color: #ed4c78;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ed4c78;
  --bs-btn-hover-border-color: #ed4c78;
  --bs-btn-focus-shadow-rgb: 237, 76, 120;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ed4c78;
  --bs-btn-active-border-color: #ed4c78;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ed4c78;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ed4c78;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f7faff;
  --bs-btn-border-color: #f7faff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7faff;
  --bs-btn-hover-border-color: #f7faff;
  --bs-btn-focus-shadow-rgb: 247, 250, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7faff;
  --bs-btn-active-border-color: #f7faff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f7faff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f7faff;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #21325b;
  --bs-btn-border-color: #21325b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #21325b;
  --bs-btn-hover-border-color: #21325b;
  --bs-btn-focus-shadow-rgb: 33, 50, 91;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #21325b;
  --bs-btn-active-border-color: #21325b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #21325b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #21325b;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #8c98a4;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 130, 143;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.3125rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.3125rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.625rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #677788;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.5rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 0.5rem;
  --bs-dropdown-divider-bg: #e7eaf3;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.75rem 1rem rgba(189, 197, 209, 0.3);
  --bs-dropdown-link-color: #1e2022;
  --bs-dropdown-link-hover-color: #1b1d1f;
  --bs-dropdown-link-hover-bg: rgba(189, 197, 209, 0.2);
  --bs-dropdown-link-active-color: #1e2022;
  --bs-dropdown-link-active-bg: rgba(189, 197, 209, 0.2);
  --bs-dropdown-link-disabled-color: #97a4af;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #8c98a4;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e7eaf3;
  --bs-dropdown-bg: #71869d;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e7eaf3;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e7eaf3;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #1e2022;
  --bs-dropdown-link-active-bg: rgba(189, 197, 209, 0.2);
  --bs-dropdown-link-disabled-color: #97a4af;
  --bs-dropdown-header-color: #97a4af;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.3125rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #21325b;
  --bs-nav-link-hover-color: #004d57;
  --bs-nav-link-disabled-color: #8c98a4;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0.1875rem;
  --bs-nav-tabs-border-color: rgba(33, 50, 91, 0.1);
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: rgba(33, 50, 91, 0.1);
  --bs-nav-tabs-link-active-color: #006C7B;
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-tabs-link-active-border-color: #006C7B;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.5rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: rgba(189, 197, 209, 0.2);
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: #677788;
  --bs-navbar-hover-color: #006C7B;
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: #677788;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #677788;
  --bs-navbar-brand-hover-color: #677788;
  --bs-navbar-nav-link-padding-x: 0.75rem;
  --bs-navbar-toggler-padding-y: 0.5rem;
  --bs-navbar-toggler-padding-x: 0.5rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23677788' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.3125rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: #fff;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(33, 50, 91, 0.1);
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  --bs-card-inner-border-radius: 0.4375rem;
  --bs-card-cap-padding-y: 2rem;
  --bs-card-cap-padding-x: 2rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 2rem 2rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #677788;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: all 0.2s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: rgba(33, 50, 91, 0.1);
  --bs-accordion-border-width: 0.0625rem;
  --bs-accordion-border-radius: 0.5rem;
  --bs-accordion-inner-border-radius: 0.4375rem;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-color: #21325b;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2321325b'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(0deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23006C7B'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: rgba(140, 152, 164, 0.25);
  --bs-accordion-btn-focus-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 0.75rem;
  --bs-accordion-active-color: #00616f;
  --bs-accordion-active-bg: #fff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #97a4af;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #8c98a4;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #677788;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: #e7eaf3;
  --bs-pagination-border-radius: 0.3125rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #f8fafd;
  --bs-pagination-hover-border-color: #e7eaf3;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #f8fafd;
  --bs-pagination-focus-box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #006C7B;
  --bs-pagination-active-border-color: #006C7B;
  --bs-pagination-disabled-color: #8c98a4;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #e7eaf3;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.25rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.75rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.3125rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.3125rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0.75rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0.0625rem solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 2.25rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 0.75rem;
}

.alert-primary {
  --bs-alert-color: #00414a;
  --bs-alert-bg: #cce2e5;
  --bs-alert-border-color: #b3d3d7;
}
.alert-primary .alert-link {
  color: #00343b;
}

.alert-secondary {
  --bs-alert-color: #44505e;
  --bs-alert-bg: #e3e7eb;
  --bs-alert-border-color: #d4dbe2;
}
.alert-secondary .alert-link {
  color: #36404b;
}

.alert-success {
  --bs-alert-color: #007964;
  --bs-alert-bg: #ccf4ed;
  --bs-alert-border-color: #b3efe5;
}
.alert-success .alert-link {
  color: #006150;
}

.alert-info {
  --bs-alert-color: #056372;
  --bs-alert-bg: #ceedf2;
  --bs-alert-border-color: #b5e4ec;
}
.alert-info .alert-link {
  color: #044f5b;
}

.alert-warning {
  --bs-alert-color: #93795c;
  --bs-alert-bg: #fdf4eb;
  --bs-alert-border-color: #fcefe0;
}
.alert-warning .alert-link {
  color: #76614a;
}

.alert-danger {
  --bs-alert-color: #8e2e48;
  --bs-alert-bg: #fbdbe4;
  --bs-alert-border-color: #fac9d7;
}
.alert-danger .alert-link {
  color: #72253a;
}

.alert-light {
  --bs-alert-color: #949699;
  --bs-alert-bg: #fdfeff;
  --bs-alert-border-color: #fdfeff;
}
.alert-light .alert-link {
  color: #76787a;
}

.alert-dark {
  --bs-alert-color: #141e37;
  --bs-alert-bg: #d3d6de;
  --bs-alert-border-color: #bcc2ce;
}
.alert-dark .alert-link {
  color: #10182c;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
.progress {
  --bs-progress-height: 0.5rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e7eaf3;
  --bs-progress-border-radius: 0.5rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #006C7B;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #1e2022;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #e7eaf3;
  --bs-list-group-border-width: 0.0625rem;
  --bs-list-group-border-radius: 0.5rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 1rem;
  --bs-list-group-action-color: #677788;
  --bs-list-group-action-hover-color: #677788;
  --bs-list-group-action-hover-bg: #f7faff;
  --bs-list-group-action-active-color: #677788;
  --bs-list-group-action-active-bg: #f8fafd;
  --bs-list-group-disabled-color: #8c98a4;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #006C7B;
  --bs-list-group-active-border-color: #006C7B;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00414a;
  background-color: #cce2e5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00414a;
  background-color: #b8cbce;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #00414a;
  border-color: #00414a;
}

.list-group-item-secondary {
  color: #44505e;
  background-color: #e3e7eb;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #44505e;
  background-color: #ccd0d4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #44505e;
  border-color: #44505e;
}

.list-group-item-success {
  color: #007964;
  background-color: #ccf4ed;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #007964;
  background-color: #b8dcd5;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #007964;
  border-color: #007964;
}

.list-group-item-info {
  color: #056372;
  background-color: #ceedf2;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #056372;
  background-color: #b9d5da;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #056372;
  border-color: #056372;
}

.list-group-item-warning {
  color: #93795c;
  background-color: #fdf4eb;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #93795c;
  background-color: #e4dcd4;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #93795c;
  border-color: #93795c;
}

.list-group-item-danger {
  color: #8e2e48;
  background-color: #fbdbe4;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #8e2e48;
  background-color: #e2c5cd;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8e2e48;
  border-color: #8e2e48;
}

.list-group-item-light {
  color: #949699;
  background-color: #fdfeff;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #949699;
  background-color: #e4e5e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #949699;
  border-color: #949699;
}

.list-group-item-dark {
  color: #141e37;
  background-color: #d3d6de;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141e37;
  background-color: #bec1c8;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141e37;
  border-color: #141e37;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 1rem;
  --bs-toast-padding-y: 1rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 0.0625rem;
  --bs-toast-border-color: rgba(33, 50, 91, 0.1);
  --bs-toast-border-radius: 0.5rem;
  --bs-toast-box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  --bs-toast-header-color: #8c98a4;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 101;
  --bs-modal-width: 550px;
  --bs-modal-padding: 2rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: transparent;
  --bs-modal-border-width: 0.0625rem;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  --bs-modal-inner-border-radius: 0.6875rem;
  --bs-modal-header-padding-x: 2rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 2rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 0;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 0.0625rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 100;
  --bs-backdrop-bg: rgba(33, 50, 91, 0.25);
  --bs-backdrop-opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #21325b;
  --bs-tooltip-border-radius: 0.5rem;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 1rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 0.0625rem;
  --bs-popover-border-color: transparent;
  --bs-popover-border-radius: 0.75rem;
  --bs-popover-inner-border-radius: 0.6875rem;
  --bs-popover-box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  --bs-popover-header-padding-x: 1.5rem;
  --bs-popover-header-padding-y: 1rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #1e2022;
  --bs-popover-header-bg: #f8fafd;
  --bs-popover-body-padding-x: 1.5rem;
  --bs-popover-body-padding-y: 1.5rem;
  --bs-popover-body-color: #677788;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 2.5rem;
  --bs-offcanvas-padding-y: 2.5rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 0.0625rem;
  --bs-offcanvas-border-color: transparent;
  --bs-offcanvas-box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 50, 91, 0.25);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 1;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 108, 123, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(113, 134, 157, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(0, 201, 167, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(9, 165, 190, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(245, 202, 153, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(237, 76, 120, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(247, 250, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 50, 91, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #006C7B !important;
}
.link-primary:hover, .link-primary:focus {
  color: #005662 !important;
}

.link-secondary {
  color: #71869d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #5a6b7e !important;
}

.link-success {
  color: #00c9a7 !important;
}
.link-success:hover, .link-success:focus {
  color: #00a186 !important;
}

.link-info {
  color: #09a5be !important;
}
.link-info:hover, .link-info:focus {
  color: #078498 !important;
}

.link-warning {
  color: #f5ca99 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #f7d5ad !important;
}

.link-danger {
  color: #ed4c78 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #be3d60 !important;
}

.link-light {
  color: #f7faff !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fbff !important;
}

.link-dark {
  color: #21325b !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a2849 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125) !important;
}

.shadow-sm {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25) !important;
}

.shadow-lg {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-xl {
  box-shadow: 0rem 3.75rem 5rem 0rem rgba(140, 152, 164, 0.3) !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-white-10 {
  --bs-border-opacity: 1;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-35 {
  width: 35% !important;
}

.w-65 {
  width: 65% !important;
}

.w-85 {
  width: 85% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8c98a4 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-white-70 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.content-space-t-0 {
  padding-top: 0 !important;
}

.content-space-t-1 {
  padding-top: 3rem !important;
}

.content-space-t-2 {
  padding-top: 5rem !important;
}

.content-space-t-3 {
  padding-top: 7.5rem !important;
}

.content-space-t-4 {
  padding-top: 10rem !important;
}

.content-space-t-5 {
  padding-top: 12.5rem !important;
}

.content-space-t-auto {
  padding-top: auto !important;
}

.content-space-b-0 {
  padding-bottom: 0 !important;
}

.content-space-b-1 {
  padding-bottom: 3rem !important;
}

.content-space-b-2 {
  padding-bottom: 5rem !important;
}

.content-space-b-3 {
  padding-bottom: 7.5rem !important;
}

.content-space-b-4 {
  padding-bottom: 10rem !important;
}

.content-space-b-5 {
  padding-bottom: 12.5rem !important;
}

.content-space-b-auto {
  padding-bottom: auto !important;
}

.content-space-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.content-space-1 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.content-space-2 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.content-space-3 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.content-space-4 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.content-space-5 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.content-space-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.zi-n1 {
  z-index: -1 !important;
}

.zi-0 {
  z-index: 0 !important;
}

.zi-1 {
  z-index: 1 !important;
}

.zi-2 {
  z-index: 2 !important;
}

.zi-3 {
  z-index: 3 !important;
}

.zi-999 {
  z-index: 999 !important;
}

.bg-soft-primary {
  background-color: rgba(0, 108, 123, 0.1) !important;
}

.bg-soft-secondary {
  background-color: rgba(113, 134, 157, 0.1) !important;
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1) !important;
}

.bg-soft-info {
  background-color: rgba(9, 165, 190, 0.1) !important;
}

.bg-soft-warning {
  background-color: rgba(245, 202, 153, 0.1) !important;
}

.bg-soft-danger {
  background-color: rgba(237, 76, 120, 0.1) !important;
}

.bg-soft-light {
  background-color: rgba(247, 250, 255, 0.1) !important;
}

.bg-soft-dark {
  background-color: rgba(33, 50, 91, 0.1) !important;
}

.min-h-100 {
  min-height: 100% !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .top-sm-50 {
    top: 50% !important;
  }
  .top-sm-100 {
    top: 100% !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .bottom-sm-50 {
    bottom: 50% !important;
  }
  .bottom-sm-100 {
    bottom: 100% !important;
  }
  .start-sm-0 {
    left: 0 !important;
  }
  .start-sm-50 {
    left: 50% !important;
  }
  .start-sm-100 {
    left: 100% !important;
  }
  .end-sm-0 {
    right: 0 !important;
  }
  .end-sm-50 {
    right: 50% !important;
  }
  .end-sm-100 {
    right: 100% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .vh-sm-30 {
    height: 30vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .vh-sm-70 {
    height: 70vh !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 2.5rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 3.5rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -3rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -3rem !important;
  }
  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -3rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-6 {
    gap: 2.5rem !important;
  }
  .gap-sm-7 {
    gap: 3rem !important;
  }
  .gap-sm-8 {
    gap: 3.5rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .content-space-t-sm-0 {
    padding-top: 0 !important;
  }
  .content-space-t-sm-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-sm-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-sm-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-sm-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-sm-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-sm-auto {
    padding-top: auto !important;
  }
  .content-space-b-sm-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-sm-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-sm-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-sm-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-sm-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-sm-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-sm-auto {
    padding-bottom: auto !important;
  }
  .content-space-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-sm-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-sm-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-sm-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-sm-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-sm-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
  .start-md-0 {
    left: 0 !important;
  }
  .start-md-50 {
    left: 50% !important;
  }
  .start-md-100 {
    left: 100% !important;
  }
  .end-md-0 {
    right: 0 !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .vh-md-30 {
    height: 30vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-70 {
    height: 70vh !important;
  }
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 3.5rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 2.5rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 3.5rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -3rem !important;
  }
  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n6 {
    margin-right: -2.5rem !important;
  }
  .me-md-n7 {
    margin-right: -3rem !important;
  }
  .me-md-n8 {
    margin-right: -3.5rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -3rem !important;
  }
  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 2.5rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 3.5rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 2.5rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 3.5rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 2.5rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 3.5rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 4.5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-6 {
    gap: 2.5rem !important;
  }
  .gap-md-7 {
    gap: 3rem !important;
  }
  .gap-md-8 {
    gap: 3.5rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .content-space-t-md-0 {
    padding-top: 0 !important;
  }
  .content-space-t-md-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-md-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-md-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-md-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-md-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-md-auto {
    padding-top: auto !important;
  }
  .content-space-b-md-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-md-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-md-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-md-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-md-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-md-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-md-auto {
    padding-bottom: auto !important;
  }
  .content-space-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-md-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-md-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-md-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-md-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-md-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .start-lg-0 {
    left: 0 !important;
  }
  .start-lg-50 {
    left: 50% !important;
  }
  .start-lg-100 {
    left: 100% !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .vh-lg-30 {
    height: 30vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-70 {
    height: 70vh !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 2.5rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 3.5rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -3rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -3rem !important;
  }
  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -3rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-6 {
    gap: 2.5rem !important;
  }
  .gap-lg-7 {
    gap: 3rem !important;
  }
  .gap-lg-8 {
    gap: 3.5rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .content-space-t-lg-0 {
    padding-top: 0 !important;
  }
  .content-space-t-lg-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-lg-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-lg-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-lg-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-lg-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-lg-auto {
    padding-top: auto !important;
  }
  .content-space-b-lg-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-lg-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-lg-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-lg-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-lg-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-lg-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-lg-auto {
    padding-bottom: auto !important;
  }
  .content-space-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-lg-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-lg-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-lg-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-lg-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-lg-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .start-xl-0 {
    left: 0 !important;
  }
  .start-xl-50 {
    left: 50% !important;
  }
  .start-xl-100 {
    left: 100% !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .vh-xl-30 {
    height: 30vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-70 {
    height: 70vh !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -3rem !important;
  }
  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-6 {
    gap: 2.5rem !important;
  }
  .gap-xl-7 {
    gap: 3rem !important;
  }
  .gap-xl-8 {
    gap: 3.5rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .content-space-t-xl-0 {
    padding-top: 0 !important;
  }
  .content-space-t-xl-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-xl-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-xl-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-xl-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-xl-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-xl-auto {
    padding-top: auto !important;
  }
  .content-space-b-xl-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-xl-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-xl-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-xl-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-xl-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-xl-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-xl-auto {
    padding-bottom: auto !important;
  }
  .content-space-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-xl-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-xl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-xl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-xl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-xl-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-xl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .start-xxl-0 {
    left: 0 !important;
  }
  .start-xxl-50 {
    left: 50% !important;
  }
  .start-xxl-100 {
    left: 100% !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-35 {
    width: 35% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-85 {
    width: 85% !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .vh-xxl-30 {
    height: 30vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-70 {
    height: 70vh !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -2.5rem !important;
  }
  .m-xxl-n7 {
    margin: -3rem !important;
  }
  .m-xxl-n8 {
    margin: -3.5rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -4.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -3rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-6 {
    gap: 2.5rem !important;
  }
  .gap-xxl-7 {
    gap: 3rem !important;
  }
  .gap-xxl-8 {
    gap: 3.5rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .content-space-t-xxl-0 {
    padding-top: 0 !important;
  }
  .content-space-t-xxl-1 {
    padding-top: 3rem !important;
  }
  .content-space-t-xxl-2 {
    padding-top: 5rem !important;
  }
  .content-space-t-xxl-3 {
    padding-top: 7.5rem !important;
  }
  .content-space-t-xxl-4 {
    padding-top: 10rem !important;
  }
  .content-space-t-xxl-5 {
    padding-top: 12.5rem !important;
  }
  .content-space-t-xxl-auto {
    padding-top: auto !important;
  }
  .content-space-b-xxl-0 {
    padding-bottom: 0 !important;
  }
  .content-space-b-xxl-1 {
    padding-bottom: 3rem !important;
  }
  .content-space-b-xxl-2 {
    padding-bottom: 5rem !important;
  }
  .content-space-b-xxl-3 {
    padding-bottom: 7.5rem !important;
  }
  .content-space-b-xxl-4 {
    padding-bottom: 10rem !important;
  }
  .content-space-b-xxl-5 {
    padding-bottom: 12.5rem !important;
  }
  .content-space-b-xxl-auto {
    padding-bottom: auto !important;
  }
  .content-space-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .content-space-xxl-1 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .content-space-xxl-2 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .content-space-xxl-3 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .content-space-xxl-4 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .content-space-xxl-5 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .content-space-xxl-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v4.0.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
  Default Styles
------------------------------------*/
a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(0, 108, 123, 0.5);
}

figure {
  margin-bottom: 0;
}

dl {
  margin-bottom: 0;
}

dt {
  color: #1e2022;
}

dd {
  margin-bottom: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}

::selection {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}

.bg-primary ::-moz-selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-primary ::selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Animation
------------------------------------*/
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

/*------------------------------------
  Accordion
------------------------------------*/
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button {
  color: #21325b;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
}
@media (max-width: 575.98px) {
  .accordion-button {
    padding: 0.5rem 0.8333333333rem;
  }
}

.accordion-body {
  padding-top: 0;
}

@media (max-width: 575.98px) {
  .accordion-button,
.accordion-body {
    padding: 0.5rem 0.8333333333rem;
  }
}

.accordion-flush .accordion-button,
.accordion-flush .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion-lg .accordion-button {
  font-size: 1.125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.accordion-lg .accordion-body {
  padding-bottom: 1.5rem;
}

.accordion-btn-icon-start .accordion-button::before {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2321325b'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-btn-icon-start .accordion-button::before {
    transition: none;
  }
}
.accordion-btn-icon-start .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23006C7B'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
  transform: rotate(0deg);
}
.accordion-btn-icon-start .accordion-button::after {
  display: none;
}

/*------------------------------------
  Alert Styles
------------------------------------*/
.alert-primary {
  color: #fff;
  background-color: #006C7B;
  border-color: #006C7B;
}
.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}
.alert-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}
.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}
.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}
.alert-warning .alert-link {
  color: #000;
}

.alert-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}
.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}
.alert-light .alert-link {
  color: #000;
}

.alert-dark {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}
.alert-dark .alert-link {
  color: #fff;
}

.alert-soft-primary {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.15);
}
.alert-soft-primary .alert-link {
  color: #006C7B;
}
.alert-soft-primary .alert-link:hover {
  color: #004d57;
}

.alert-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.15);
}
.alert-soft-secondary .alert-link {
  color: #71869d;
}
.alert-soft-secondary .alert-link:hover {
  color: #60748b;
}

.alert-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.15);
}
.alert-soft-success .alert-link {
  color: #00c9a7;
}
.alert-soft-success .alert-link:hover {
  color: #00a589;
}

.alert-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.15);
}
.alert-soft-info .alert-link {
  color: #09a5be;
}
.alert-soft-info .alert-link:hover {
  color: #07879c;
}

.alert-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.15);
}
.alert-soft-warning .alert-link {
  color: #f5ca99;
}
.alert-soft-warning .alert-link:hover {
  color: #f2b978;
}

.alert-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.15);
}
.alert-soft-danger .alert-link {
  color: #ed4c78;
}
.alert-soft-danger .alert-link:hover {
  color: #ea2c60;
}

.alert-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.15);
}
.alert-soft-light .alert-link {
  color: #f7faff;
}
.alert-soft-light .alert-link:hover {
  color: #d3e4ff;
}

.alert-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.15);
}
.alert-soft-dark .alert-link {
  color: #21325b;
}
.alert-soft-dark .alert-link:hover {
  color: #172441;
}

.alert-white {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  background-color: #fff;
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.5rem;
}
.avatar:not(img) {
  background-color: #fff;
}

.avatar-img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.avatar-circle {
  border-radius: 50%;
}
.avatar-circle .avatar,
.avatar-circle .avatar-initials,
.avatar-circle .avatar-img {
  border-radius: 50%;
}

.avatar-centered {
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/*------------------------------------
  Avatar Group
------------------------------------*/
.avatar-group {
  display: flex;
}
.avatar-group .avatar:hover {
  z-index: 2;
}
.avatar-group .avatar-circle .avatar-initials {
  border-radius: 50%;
}
.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials,
.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials,
.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-initials {
  border: 2px solid #fff;
}
.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
  border: 5px solid #fff;
}
.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
  border: 7px solid #fff;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.6125rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.875rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.5rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Group Sizes
------------------------------------*/
.avatar-group .avatar,
.avatar-group .avatar-initials {
  width: 3.125rem;
  height: 3.125rem;
}
.avatar-group .avatar-initials {
  font-size: 1rem;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group-xs .avatar,
.avatar-group-xs .avatar-initials {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar-group-xs .avatar-initials {
  font-size: 0.75rem;
}
.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}

.avatar-group-sm .avatar,
.avatar-group-sm .avatar-initials {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-group-sm .avatar-initials {
  font-size: 0.8125rem;
}
.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}

.avatar-group-lg .avatar,
.avatar-group-lg .avatar-initials {
  width: 4.25rem;
  height: 4.25rem;
}
.avatar-group-lg .avatar-initials {
  font-size: 1.25rem;
}
.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xl .avatar,
.avatar-group-xl .avatar-initials {
  width: 5.625rem;
  height: 5.625rem;
}
.avatar-group-xl .avatar-initials {
  font-size: 2rem;
}
.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xxl .avatar,
.avatar-group-xxl .avatar-initials {
  width: 7rem;
  height: 7rem;
}
.avatar-group-xxl .avatar-initials {
  font-size: 3rem;
}
.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/
.avatar.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}
.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xss,
.avatar-xss .avatar-initials {
  width: 1rem;
  height: 1rem;
}
.avatar-xss .avatar-img {
  width: 1rem;
}
.avatar-xss .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs,
.avatar-xs .avatar-initials {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar-xs .avatar-img {
  width: 1.75rem;
}
.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}
.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.1171875rem;
  right: -0.1171875rem;
}
.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xs,
.avatar-xs .avatar-initials {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar-xs .avatar-img {
  width: 1.75rem;
}
.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}
.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.1171875rem;
  right: -0.1171875rem;
}
.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-sm,
.avatar-sm .avatar-initials {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .avatar-img {
  width: 2.5rem;
}
.avatar-sm .avatar-initials {
  font-size: 0.8125rem;
}
.avatar-sm.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}
.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.09375rem;
  right: -0.09375rem;
}
.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.40625rem;
  right: -0.40625rem;
}

.avatar-lg,
.avatar-lg .avatar-initials {
  width: 4.25rem;
  height: 4.25rem;
}
.avatar-lg .avatar-img {
  width: 4.25rem;
}
.avatar-lg .avatar-initials {
  font-size: 1.25rem;
}
.avatar-lg.avatar-circle .avatar-status {
  bottom: -0.13125rem;
  right: -0.13125rem;
}
.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.09375rem;
  right: 0.09375rem;
}
.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.203125rem;
  right: -0.203125rem;
}

.avatar-xl,
.avatar-xl .avatar-initials {
  width: 5.625rem;
  height: 5.625rem;
}
.avatar-xl .avatar-img {
  width: 5.625rem;
}
.avatar-xl .avatar-initials {
  font-size: 2rem;
}
.avatar-xl.avatar-circle .avatar-status {
  bottom: 0.1640625rem;
  right: 0.1640625rem;
}
.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.234375rem;
  right: 0.234375rem;
}
.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.1354166667rem;
  right: 0.1354166667rem;
}

.avatar-xxl,
.avatar-xxl .avatar-initials {
  width: 7rem;
  height: 7rem;
}
.avatar-xxl .avatar-img {
  width: 7rem;
}
.avatar-xxl .avatar-initials {
  font-size: 3rem;
}
.avatar-xxl.avatar-circle .avatar-status {
  bottom: 0.75rem;
  right: 0.75rem;
}
.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 0.875rem;
  right: 0.875rem;
}
.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 0.65rem;
  right: 0.65rem;
}

/*------------------------------------
  Avatar Status
------------------------------------*/
.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5rem;
}

.avatar-lg-status {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/
.avatar-primary .avatar-initials {
  color: #fff;
  background-color: #006C7B;
}

.avatar-status-primary {
  color: #fff;
  background-color: #006C7B;
}

.avatar-secondary .avatar-initials {
  color: #fff;
  background-color: #71869d;
}

.avatar-status-secondary {
  color: #fff;
  background-color: #71869d;
}

.avatar-success .avatar-initials {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-info .avatar-initials {
  color: #fff;
  background-color: #09a5be;
}

.avatar-status-info {
  color: #fff;
  background-color: #09a5be;
}

.avatar-warning .avatar-initials {
  color: #000;
  background-color: #f5ca99;
}

.avatar-status-warning {
  color: #000;
  background-color: #f5ca99;
}

.avatar-danger .avatar-initials {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-status-danger {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-light .avatar-initials {
  color: #000;
  background-color: #f7faff;
}

.avatar-status-light {
  color: #000;
  background-color: #f7faff;
}

.avatar-dark .avatar-initials {
  color: #fff;
  background-color: #21325b;
}

.avatar-status-dark {
  color: #fff;
  background-color: #21325b;
}

.avatar-soft-primary .avatar-initials {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}

.avatar-status-soft-primary {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}

.avatar-soft-secondary .avatar-initials {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-status-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-soft-success .avatar-initials {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-status-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-soft-info .avatar-initials {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-status-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-soft-warning .avatar-initials {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-status-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-soft-danger .avatar-initials {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-status-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-soft-light .avatar-initials {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-status-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-soft-dark .avatar-initials {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.avatar-status-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Avatar Ratio
------------------------------------*/
.avatar.avatar-4x3 {
  width: 4.1666666667rem;
  height: auto;
  border-radius: 0;
}
.avatar.avatar-4x3 .avatar-img {
  width: 4.1666666667rem;
  height: inherit;
}

.avatar-xss.avatar-4x3 {
  width: 1.3333333333rem;
  height: auto;
  border-radius: 0;
}
.avatar-xss.avatar-4x3 .avatar-img {
  width: 1.3333333333rem;
  height: inherit;
}

.avatar-xs.avatar-4x3 {
  width: 2.3333333333rem;
  height: auto;
  border-radius: 0;
}
.avatar-xs.avatar-4x3 .avatar-img {
  width: 2.3333333333rem;
  height: inherit;
}

.avatar-sm.avatar-4x3 {
  width: 3.3333333333rem;
  height: auto;
  border-radius: 0;
}
.avatar-sm.avatar-4x3 .avatar-img {
  width: 3.3333333333rem;
  height: inherit;
}

.avatar-lg.avatar-4x3 {
  width: 5.6666666667rem;
  height: auto;
  border-radius: 0;
}
.avatar-lg.avatar-4x3 .avatar-img {
  width: 5.6666666667rem;
  height: inherit;
}

.avatar-xl.avatar-4x3 {
  width: 7.5rem;
  height: auto;
  border-radius: 0;
}
.avatar-xl.avatar-4x3 .avatar-img {
  width: 7.5rem;
  height: inherit;
}

.avatar-xxl.avatar-4x3 {
  width: 9.3333333333rem;
  height: auto;
  border-radius: 0;
}
.avatar-xxl.avatar-4x3 .avatar-img {
  width: 9.3333333333rem;
  height: inherit;
}

/*------------------------------------
  Badge
------------------------------------*/
.badge {
  line-height: normal;
}

/*------------------------------------
  Banner
------------------------------------*/
.banner-half-middle-x {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  z-index: -1;
}
.banner-half-middle-x::before {
  position: absolute;
  top: 50%;
  right: 100%;
  width: 80%;
  height: 80%;
  background-image: linear-gradient(transparent, rgba(0, 108, 123, 0.075) 50%, transparent);
  content: "";
  transform: translateY(-50%);
}

/*------------------------------------
  Blockquote
------------------------------------*/
.blockquote {
  color: #1e2022;
}

.blockquote-left-border {
  color: #677788;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
  padding-left: 0.75rem;
}

.blockquote-footer {
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.blockquote-footer::before {
  content: "";
}

.blockquote-footer-source {
  display: block;
  color: #677788;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.blockquote-light {
  color: #fff;
}
.blockquote-light .blockquote-footer-source {
  color: rgba(255, 255, 255, 0.7);
}

.blockquote-sm {
  font-size: 1rem;
}

.blockquote-lg {
  font-size: 1.75rem;
}

/*------------------------------------
  Brand
------------------------------------*/
.brand {
  width: 100%;
  max-width: 7.5rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/
.breadcrumb .breadcrumb-item {
  color: #1e2022;
}

.breadcrumb-light .breadcrumb-item {
  color: #fff;
}
.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}
.breadcrumb-light .breadcrumb-link {
  color: rgba(255, 255, 255, 0.5);
}
.breadcrumb-light .breadcrumb-link:hover {
  color: #fff;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn:hover {
  border-color: transparent;
}

.btn-link {
  font-weight: 400;
}
.btn-link:focus {
  box-shadow: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  border-color: transparent;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  border-color: transparent;
}

.btn.dropdown-toggle {
  display: inline-flex;
  align-items: center;
}

.btn-transition {
  transition: all 0.2s ease-in-out;
}
.btn-transition:hover, .btn-transition:focus {
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-primary, .btn-primary:hover, .btn-primary:focus {
  box-shadow: 0 4px 11px rgba(0, 108, 123, 0.35);
}

.btn-check:focus + .btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.35);
}

.btn-check:focus + .btn-success, .btn-success:hover, .btn-success:focus {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-check:focus + .btn-info, .btn-info:hover, .btn-info:focus {
  box-shadow: 0 4px 11px rgba(9, 165, 190, 0.35);
}

.btn-check:focus + .btn-warning, .btn-warning:hover, .btn-warning:focus {
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.35);
}

.btn-check:focus + .btn-danger, .btn-danger:hover, .btn-danger:focus {
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.35);
}

.btn-check:focus + .btn-light, .btn-light:hover, .btn-light:focus {
  box-shadow: 0 4px 11px rgba(247, 250, 255, 0.35);
}

.btn-check:focus + .btn-dark, .btn-dark:hover, .btn-dark:focus {
  box-shadow: 0 4px 11px rgba(33, 50, 91, 0.35);
}

.btn-toggle .btn-toggle-default {
  display: inline-block;
}
.btn-toggle .btn-toggle-toggled {
  display: none;
}
.btn-toggle.toggled .btn-toggle-default {
  display: none;
}
.btn-toggle.toggled .btn-toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Custom Buttons
------------------------------------*/
.btn-white {
  background-color: #fff;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
}
.btn-white.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-white, .btn-white:hover, .btn-white:focus {
  color: #004d57;
  border-color: rgba(33, 50, 91, 0.1);
  background-color: #fff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.btn-check:focus + .btn-white.dropdown-toggle::after, .btn-white:hover.dropdown-toggle::after, .btn-white:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-white.disabled, .btn-white:disabled {
  color: #bdc5d1;
  background-color: rgba(231, 234, 243, 0.5);
}

.btn-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/
.btn-ghost-primary {
  color: #006C7B;
  background-color: transparent;
}
.btn-ghost-primary:hover, .btn-check:focus + .btn-ghost-primary, .btn-ghost-primary:focus, .btn-check:checked + .btn-ghost-primary, .btn-check:active + .btn-ghost-primary, .btn-ghost-primary:active, .btn-ghost-primary.active, .show > .btn-ghost-primary.dropdown-toggle {
  color: #006C7B;
  border-color: transparent;
  background-color: rgba(0, 108, 123, 0.1);
}
.btn-ghost-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-primary.btn-no-focus, .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus.focus, .btn-ghost-primary.btn-no-focus:focus {
  color: #006C7B;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus:hover:hover, .btn-ghost-primary.btn-no-focus.focus:hover, .btn-ghost-primary.btn-no-focus:focus:hover {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition, .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus, .btn-ghost-primary.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-primary.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}
.btn-ghost-secondary:hover, .btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:focus, .btn-check:checked + .btn-ghost-secondary, .btn-check:active + .btn-ghost-secondary, .btn-ghost-secondary:active, .btn-ghost-secondary.active, .show > .btn-ghost-secondary.dropdown-toggle {
  color: #71869d;
  border-color: transparent;
  background-color: rgba(0, 108, 123, 0.1);
}
.btn-ghost-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus, .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus.focus, .btn-ghost-secondary.btn-no-focus:focus {
  color: #71869d;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}
.btn-ghost-success:hover, .btn-check:focus + .btn-ghost-success, .btn-ghost-success:focus, .btn-check:checked + .btn-ghost-success, .btn-check:active + .btn-ghost-success, .btn-ghost-success:active, .btn-ghost-success.active, .show > .btn-ghost-success.dropdown-toggle {
  color: #00c9a7;
  border-color: transparent;
  background-color: rgba(0, 201, 167, 0.1);
}
.btn-ghost-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-success.btn-no-focus, .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus.focus, .btn-ghost-success.btn-no-focus:focus {
  color: #00c9a7;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus:hover:hover, .btn-ghost-success.btn-no-focus.focus:hover, .btn-ghost-success.btn-no-focus:focus:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition, .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus, .btn-ghost-success.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition:hover:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus:hover, .btn-ghost-success.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-info {
  color: #09a5be;
  background-color: transparent;
}
.btn-ghost-info:hover, .btn-check:focus + .btn-ghost-info, .btn-ghost-info:focus, .btn-check:checked + .btn-ghost-info, .btn-check:active + .btn-ghost-info, .btn-ghost-info:active, .btn-ghost-info.active, .show > .btn-ghost-info.dropdown-toggle {
  color: #09a5be;
  border-color: transparent;
  background-color: rgba(9, 165, 190, 0.1);
}
.btn-ghost-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-info.btn-no-focus, .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus.focus, .btn-ghost-info.btn-no-focus:focus {
  color: #09a5be;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus:hover:hover, .btn-ghost-info.btn-no-focus.focus:hover, .btn-ghost-info.btn-no-focus:focus:hover {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition, .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus, .btn-ghost-info.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition:hover:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus:hover, .btn-ghost-info.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}
.btn-ghost-warning:hover, .btn-check:focus + .btn-ghost-warning, .btn-ghost-warning:focus, .btn-check:checked + .btn-ghost-warning, .btn-check:active + .btn-ghost-warning, .btn-ghost-warning:active, .btn-ghost-warning.active, .show > .btn-ghost-warning.dropdown-toggle {
  color: #f5ca99;
  border-color: transparent;
  background-color: rgba(245, 202, 153, 0.1);
}
.btn-ghost-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-warning.btn-no-focus, .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus.focus, .btn-ghost-warning.btn-no-focus:focus {
  color: #f5ca99;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus:hover:hover, .btn-ghost-warning.btn-no-focus.focus:hover, .btn-ghost-warning.btn-no-focus:focus:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition, .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus, .btn-ghost-warning.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition:hover:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus:hover, .btn-ghost-warning.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-danger {
  color: #ed4c78;
  background-color: transparent;
}
.btn-ghost-danger:hover, .btn-check:focus + .btn-ghost-danger, .btn-ghost-danger:focus, .btn-check:checked + .btn-ghost-danger, .btn-check:active + .btn-ghost-danger, .btn-ghost-danger:active, .btn-ghost-danger.active, .show > .btn-ghost-danger.dropdown-toggle {
  color: #ed4c78;
  border-color: transparent;
  background-color: rgba(237, 76, 120, 0.1);
}
.btn-ghost-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-danger.btn-no-focus, .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus.focus, .btn-ghost-danger.btn-no-focus:focus {
  color: #ed4c78;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus:hover:hover, .btn-ghost-danger.btn-no-focus.focus:hover, .btn-ghost-danger.btn-no-focus:focus:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition, .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus, .btn-ghost-danger.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition:hover:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus:hover, .btn-ghost-danger.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-light {
  color: #f7faff;
  background-color: transparent;
}
.btn-ghost-light:hover, .btn-check:focus + .btn-ghost-light, .btn-ghost-light:focus, .btn-check:checked + .btn-ghost-light, .btn-check:active + .btn-ghost-light, .btn-ghost-light:active, .btn-ghost-light.active, .show > .btn-ghost-light.dropdown-toggle {
  color: #f7faff;
  border-color: transparent;
  background-color: rgba(247, 250, 255, 0.1);
}
.btn-ghost-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-light.btn-no-focus, .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus.focus, .btn-ghost-light.btn-no-focus:focus {
  color: #f7faff;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus:hover:hover, .btn-ghost-light.btn-no-focus.focus:hover, .btn-ghost-light.btn-no-focus:focus:hover {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition, .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus, .btn-ghost-light.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition:hover:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus:hover, .btn-ghost-light.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-ghost-dark {
  color: #21325b;
  background-color: transparent;
}
.btn-ghost-dark:hover, .btn-check:focus + .btn-ghost-dark, .btn-ghost-dark:focus, .btn-check:checked + .btn-ghost-dark, .btn-check:active + .btn-ghost-dark, .btn-ghost-dark:active, .btn-ghost-dark.active, .show > .btn-ghost-dark.dropdown-toggle {
  color: #21325b;
  border-color: transparent;
  background-color: rgba(33, 50, 91, 0.1);
}
.btn-ghost-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-ghost-dark.btn-no-focus, .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus.focus, .btn-ghost-dark.btn-no-focus:focus {
  color: #21325b;
  background-color: unset;
  box-shadow: none;
}
.btn-check:focus + .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus:hover:hover, .btn-ghost-dark.btn-no-focus.focus:hover, .btn-ghost-dark.btn-no-focus:focus:hover {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  transform: translateY(-0.1875rem);
}
.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition, .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus, .btn-ghost-dark.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition:hover:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus:hover, .btn-ghost-dark.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:hover, .btn-ghost-secondary:focus {
  color: #006C7B;
}
.btn-check:focus + .btn-ghost-secondary.dropdown-toggle::after, .btn-ghost-secondary:hover.dropdown-toggle::after, .btn-ghost-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  transform: translateY(0);
}
.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  transform: translateY(-0.1875rem);
}

/*------------------------------------
  Button Groups
------------------------------------*/
.btn-group-segment {
  position: relative;
  display: inline-flex;
  justify-content: center;
  background-color: #f8fafd;
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem;
}
.btn-group-segment .btn {
  color: #677788;
}
.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}
.btn-group-segment .btn:hover {
  color: #004d57;
}
.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:nth-child(n+3),
.btn-group-segment > :not(.btn-check) + .btn,
.btn-group-segment > .btn-group:not(:first-child) > .btn {
  border-radius: 0.5rem;
}
.btn-group-segment > .btn-check:checked + .btn,
.btn-group-segment > .btn-check:focus + .btn {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}
.btn-group-segment.btn-group-pills {
  border-radius: 50rem;
}
.btn-group-segment.btn-group-pills > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn {
  border-radius: 50rem;
}

/*------------------------------------
  Button Soft
------------------------------------*/
.btn-soft-primary {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
  border-color: transparent;
}
.btn-soft-primary:hover, .btn-check:focus + .btn-soft-primary, .btn-soft-primary:focus {
  color: #fff;
  background-color: #006C7B;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-primary, .btn-check:active + .btn-soft-primary, .btn-soft-primary:active, .btn-soft-primary.active, .show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #006C7B;
  border-color: transparent;
}
.btn-soft-primary:disabled, .btn-soft-primary.disabled {
  color: #006C7B;
  background-color: #006C7B;
  border-color: transparent;
}

.btn-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}
.btn-soft-secondary:hover, .btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:focus {
  color: #fff;
  background-color: #71869d;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-secondary, .btn-check:active + .btn-soft-secondary, .btn-soft-secondary:active, .btn-soft-secondary.active, .show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #71869d;
  border-color: transparent;
}
.btn-soft-secondary:disabled, .btn-soft-secondary.disabled {
  color: #71869d;
  background-color: #71869d;
  border-color: transparent;
}

.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}
.btn-soft-success:hover, .btn-check:focus + .btn-soft-success, .btn-soft-success:focus {
  color: #fff;
  background-color: #00c9a7;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-success, .btn-check:active + .btn-soft-success, .btn-soft-success:active, .btn-soft-success.active, .show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: transparent;
}
.btn-soft-success:disabled, .btn-soft-success.disabled {
  color: #00c9a7;
  background-color: #00c9a7;
  border-color: transparent;
}

.btn-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  border-color: transparent;
}
.btn-soft-info:hover, .btn-check:focus + .btn-soft-info, .btn-soft-info:focus {
  color: #fff;
  background-color: #09a5be;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-info, .btn-check:active + .btn-soft-info, .btn-soft-info:active, .btn-soft-info.active, .show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #09a5be;
  border-color: transparent;
}
.btn-soft-info:disabled, .btn-soft-info.disabled {
  color: #09a5be;
  background-color: #09a5be;
  border-color: transparent;
}

.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}
.btn-soft-warning:hover, .btn-check:focus + .btn-soft-warning, .btn-soft-warning:focus {
  color: #000;
  background-color: #f5ca99;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-warning, .btn-check:active + .btn-soft-warning, .btn-soft-warning:active, .btn-soft-warning.active, .show > .btn-soft-warning.dropdown-toggle {
  color: #000;
  background-color: #f5ca99;
  border-color: transparent;
}
.btn-soft-warning:disabled, .btn-soft-warning.disabled {
  color: #f5ca99;
  background-color: #f5ca99;
  border-color: transparent;
}

.btn-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}
.btn-soft-danger:hover, .btn-check:focus + .btn-soft-danger, .btn-soft-danger:focus {
  color: #fff;
  background-color: #ed4c78;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-danger, .btn-check:active + .btn-soft-danger, .btn-soft-danger:active, .btn-soft-danger.active, .show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
  border-color: transparent;
}
.btn-soft-danger:disabled, .btn-soft-danger.disabled {
  color: #ed4c78;
  background-color: #ed4c78;
  border-color: transparent;
}

.btn-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  border-color: transparent;
}
.btn-soft-light:hover, .btn-check:focus + .btn-soft-light, .btn-soft-light:focus {
  color: #000;
  background-color: #f7faff;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-light, .btn-check:active + .btn-soft-light, .btn-soft-light:active, .btn-soft-light.active, .show > .btn-soft-light.dropdown-toggle {
  color: #000;
  background-color: #f7faff;
  border-color: transparent;
}
.btn-soft-light:disabled, .btn-soft-light.disabled {
  color: #f7faff;
  background-color: #f7faff;
  border-color: transparent;
}

.btn-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  border-color: transparent;
}
.btn-soft-dark:hover, .btn-check:focus + .btn-soft-dark, .btn-soft-dark:focus {
  color: #fff;
  background-color: #21325b;
  border-color: transparent;
}
.btn-check:checked + .btn-soft-dark, .btn-check:active + .btn-soft-dark, .btn-soft-dark:active, .btn-soft-dark.active, .show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #21325b;
  border-color: transparent;
}
.btn-soft-dark:disabled, .btn-soft-dark.disabled {
  color: #21325b;
  background-color: #21325b;
  border-color: transparent;
}

.btn-soft-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-primary.dropdown-toggle::after, .btn-soft-primary:hover.dropdown-toggle::after, .btn-soft-primary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-success.dropdown-toggle::after, .btn-soft-success:hover.dropdown-toggle::after, .btn-soft-success:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-info.dropdown-toggle::after, .btn-soft-info:hover.dropdown-toggle::after, .btn-soft-info:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-warning.dropdown-toggle::after, .btn-soft-warning:hover.dropdown-toggle::after, .btn-soft-warning:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-danger.dropdown-toggle::after, .btn-soft-danger:hover.dropdown-toggle::after, .btn-soft-danger:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-light.dropdown-toggle::after, .btn-soft-light:hover.dropdown-toggle::after, .btn-soft-light:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-check:focus + .btn-soft-dark.dropdown-toggle::after, .btn-soft-dark:hover.dropdown-toggle::after, .btn-soft-dark:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:hover, .btn-soft-secondary:focus {
  color: #fff;
}
.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

/*------------------------------------
  Button Icon
------------------------------------*/
.btn-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}
.btn-icon > svg {
  width: 1rem;
  height: auto;
}

/*------------------------------------
  Outline Button Styles
------------------------------------*/
.btn-outline-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-primary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-primary.dropdown-toggle::after, .btn-check:active + .btn-outline-primary.dropdown-toggle::after, .btn-outline-primary:active.dropdown-toggle::after, .btn-outline-primary.active.dropdown-toggle::after, .btn-outline-primary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-secondary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-secondary.dropdown-toggle::after, .btn-check:active + .btn-outline-secondary.dropdown-toggle::after, .btn-outline-secondary:active.dropdown-toggle::after, .btn-outline-secondary.active.dropdown-toggle::after, .btn-outline-secondary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-success:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-success.dropdown-toggle::after, .btn-check:active + .btn-outline-success.dropdown-toggle::after, .btn-outline-success:active.dropdown-toggle::after, .btn-outline-success.active.dropdown-toggle::after, .btn-outline-success.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-info:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-info.dropdown-toggle::after, .btn-check:active + .btn-outline-info.dropdown-toggle::after, .btn-outline-info:active.dropdown-toggle::after, .btn-outline-info.active.dropdown-toggle::after, .btn-outline-info.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-warning:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-warning.dropdown-toggle::after, .btn-check:active + .btn-outline-warning.dropdown-toggle::after, .btn-outline-warning:active.dropdown-toggle::after, .btn-outline-warning.active.dropdown-toggle::after, .btn-outline-warning.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-danger:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-danger.dropdown-toggle::after, .btn-check:active + .btn-outline-danger.dropdown-toggle::after, .btn-outline-danger:active.dropdown-toggle::after, .btn-outline-danger.active.dropdown-toggle::after, .btn-outline-danger.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-light:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-light.dropdown-toggle::after, .btn-check:active + .btn-outline-light.dropdown-toggle::after, .btn-outline-light:active.dropdown-toggle::after, .btn-outline-light.active.dropdown-toggle::after, .btn-outline-light.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}
.btn-outline-dark:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}
.btn-check:checked + .btn-outline-dark.dropdown-toggle::after, .btn-check:active + .btn-outline-dark.dropdown-toggle::after, .btn-outline-dark:active.dropdown-toggle::after, .btn-outline-dark.active.dropdown-toggle::after, .btn-outline-dark.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-primary,
.btn-outline-secondary {
  border-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
.btn-xs:not(.btn-icon) {
  font-size: 0.75rem;
  padding: 0.3125rem 0.75rem;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.btn-icon.btn-xs > svg {
  width: 0.75rem;
  height: auto;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}
.btn-icon.btn-sm > svg, .btn-group-sm > .btn-icon.btn > svg {
  width: 0.8125rem;
  height: auto;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}
.btn-icon.btn-lg > svg, .btn-group-lg > .btn-icon.btn > svg {
  width: 1.25rem;
  height: auto;
}

/*------------------------------------
  Card
------------------------------------*/
.card {
  border-width: 0;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}
.card table tr th {
  font-weight: 400;
}

.card-header {
  border-width: 0;
}

.card-footer {
  border-top-width: 0;
}

.card-subtitle {
  display: block;
  text-transform: uppercase;
  font-size: 0.8125rem;
  margin-bottom: 0.25rem;
}

.card-title {
  margin-bottom: 0;
}
.card-title:not(:last-child) {
  margin-bottom: 0.5rem;
}

.card-header-title {
  margin-bottom: 0;
}
.card-header-title + .card-text {
  margin-top: 0.25rem;
}

.card-link {
  font-weight: 600;
  white-space: nowrap;
}

.card-text {
  color: #677788;
}

.card-bordered {
  border-width: 0.0625rem;
}

.card-transition {
  transition: all 0.2s ease-in-out;
}
.card-transition:hover, .card-transition:focus {
  transform: translateY(-0.1875rem) !important;
}

.card-ghost {
  background-color: transparent;
  box-shadow: none;
}

.card-dashed {
  border: 0.0625rem dashed rgba(33, 50, 91, 0.1);
}

.card-centered .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-group .card {
  box-shadow: none;
}
.card-group .card + .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card .card-header + .table-responsive {
  margin-top: -1px;
}
.card .table {
  margin-bottom: 0;
}
.card .card-table > tbody > tr:first-child > td {
  padding-top: 2rem;
}
.card .card-table > tbody > tr:last-child > td {
  padding-bottom: 2rem;
}
.card .card-table > :not(caption) > * > * {
  padding-right: 2rem;
  padding-left: 2rem;
}
.card .thead-light th:first-child {
  border-top-left-radius: 0.5rem;
}
.card .thead-light th:last-child {
  border-top-right-radius: 0.5rem;
}
.card .card-header + .table-responsive .thead-light th:first-child,
.card .card-header + .table .thead-light th:first-child {
  border-top-left-radius: 0;
}
.card .card-header + .table-responsive .thead-light th:last-child,
.card .card-header + .table .thead-light th:last-child {
  border-top-right-radius: 0;
}
.card .card-header + .table-responsive .table-thead-bordered > :not(:last-child) > :last-child > *,
.card .card-header + .table .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}
.card .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top-width: 0;
}

.card-sm > .card-header,
.card-sm > .card-footer {
  padding: 1.25rem 1.25rem;
}
.card-sm > .card-img-overlay,
.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1.25rem 1.25rem;
}
.card-sm .card-table > tbody > tr:first-child > td {
  padding-top: 1.25rem;
}
.card-sm .card-table > tbody > tr:last-child > td {
  padding-bottom: 1.25rem;
}
.card-sm .card-table > :not(caption) > * > * {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.card-lg > .card-header,
.card-lg > .card-footer {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}
.card-lg > .card-img-overlay,
.card-lg > .card-body,
.card-lg > .collapse .card-body {
  padding: 2.75rem 2.75rem;
}
.card-lg .card-table > tbody > tr:first-child > td {
  padding-top: 2.75rem;
}
.card-lg .card-table > tbody > tr:last-child > td {
  padding-bottom: 2.75rem;
}
.card-lg .card-table > :not(caption) > * > * {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.card-info-link {
  display: inline-block;
  text-align: center;
  border-width: 0.0625rem;
  box-shadow: none;
  border-radius: 50rem;
}
.card-info-link .card-body {
  padding: 0.8125rem 1.25rem;
}

.card-pinned {
  position: relative;
  display: block;
}

.card-pinned-top-start {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.card-pinned-top-end {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.card-pinned-bottom-start {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.card-pinned-bottom-end {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.card-flush {
  box-shadow: none;
}
.card-flush .card-header,
.card-flush .card-footer,
.card-flush .card-body,
.card-flush .collapse .card-body {
  padding-right: 0;
  padding-left: 0;
}
.card-flush > .card-img-top {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.card-flush.card-stretched-vertical .card-body .card-footer {
  padding-bottom: 0;
}

.card-stretched-vertical .card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-stretched-vertical .card-body .card-footer {
  padding: 0;
  margin-top: auto;
}

.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

/*------------------------------------
  Card Group Break
------------------------------------*/
@media (max-width: 575.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break > .card {
    margin-bottom: 0;
  }
  .card-group-sm-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-break > .card + .card {
    border-left: none;
  }
  .card-group-sm-break > .card:not(:first-child):not(:last-child),
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:first-child .card-header,
.card-group-sm-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-sm-break > .card:first-child .card-footer,
.card-group-sm-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-header,
.card-group-sm-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-footer,
.card-group-sm-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break > .card {
    margin-bottom: 0;
  }
  .card-group-md-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-break > .card + .card {
    border-left: none;
  }
  .card-group-md-break > .card:not(:first-child):not(:last-child),
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:first-child .card-header,
.card-group-md-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-md-break > .card:first-child .card-footer,
.card-group-md-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-header,
.card-group-md-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-footer,
.card-group-md-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break > .card {
    margin-bottom: 0;
  }
  .card-group-lg-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-break > .card + .card {
    border-left: none;
  }
  .card-group-lg-break > .card:not(:first-child):not(:last-child),
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-header,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-top,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-footer,
.card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:first-child .card-header,
.card-group-lg-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-lg-break > .card:first-child .card-footer,
.card-group-lg-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-header,
.card-group-lg-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-footer,
.card-group-lg-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
/*------------------------------------
  Card Group Row
------------------------------------*/
@media (min-width: 576px) {
  .card-group-sm-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-sm-row > * {
    padding: 0;
  }
  .card-group-sm-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-sm-row > *:not(:first-child):not(:last-child),
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-row > *:first-child > .card,
.card-group-sm-row > *:first-child > .card .card-header,
.card-group-sm-row > *:first-child > .card .card-img-top,
.card-group-sm-row > *:first-child > .card .card-footer,
.card-group-sm-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-sm-row > *:last-child > .card,
.card-group-sm-row > *:last-child > .card .card-header,
.card-group-sm-row > *:last-child > .card .card-img-top,
.card-group-sm-row > *:last-child > .card .card-footer,
.card-group-sm-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-2 > *:first-child > .card,
.card-group-sm-2 > *:first-child > .card .card-header,
.card-group-sm-2 > *:first-child > .card .card-img-top,
.card-group-sm-2 > *:first-child > .card .card-footer,
.card-group-sm-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-2 > *:nth-child(2) > .card,
.card-group-sm-2 > *:nth-child(2) > .card .card-header,
.card-group-sm-2 > *:nth-child(2) > .card .card-img-top,
.card-group-sm-2 > *:nth-child(2) > .card .card-footer,
.card-group-sm-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-2 > *:last-child > .card,
.card-group-sm-2 > *:last-child > .card .card-header,
.card-group-sm-2 > *:last-child > .card .card-img-top,
.card-group-sm-2 > *:last-child > .card .card-footer,
.card-group-sm-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-3 > *:first-child > .card,
.card-group-sm-3 > *:first-child > .card .card-header,
.card-group-sm-3 > *:first-child > .card .card-img-top,
.card-group-sm-3 > *:first-child > .card .card-footer,
.card-group-sm-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-3 > *:nth-child(3) > .card,
.card-group-sm-3 > *:nth-child(3) > .card .card-header,
.card-group-sm-3 > *:nth-child(3) > .card .card-img-top,
.card-group-sm-3 > *:nth-child(3) > .card .card-footer,
.card-group-sm-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-3 > *:last-child > .card,
.card-group-sm-3 > *:last-child > .card .card-header,
.card-group-sm-3 > *:last-child > .card .card-img-top,
.card-group-sm-3 > *:last-child > .card .card-footer,
.card-group-sm-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-4 > *:first-child > .card,
.card-group-sm-4 > *:first-child > .card .card-header,
.card-group-sm-4 > *:first-child > .card .card-img-top,
.card-group-sm-4 > *:first-child > .card .card-footer,
.card-group-sm-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-4 > *:nth-child(4) > .card,
.card-group-sm-4 > *:nth-child(4) > .card .card-header,
.card-group-sm-4 > *:nth-child(4) > .card .card-img-top,
.card-group-sm-4 > *:nth-child(4) > .card .card-footer,
.card-group-sm-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-4 > *:last-child > .card,
.card-group-sm-4 > *:last-child > .card .card-header,
.card-group-sm-4 > *:last-child > .card .card-img-top,
.card-group-sm-4 > *:last-child > .card .card-footer,
.card-group-sm-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 768px) {
  .card-group-md-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-md-row > * {
    padding: 0;
  }
  .card-group-md-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-md-row > *:not(:first-child):not(:last-child),
.card-group-md-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-md-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-row > *:first-child > .card,
.card-group-md-row > *:first-child > .card .card-header,
.card-group-md-row > *:first-child > .card .card-img-top,
.card-group-md-row > *:first-child > .card .card-footer,
.card-group-md-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-md-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-md-row > *:last-child > .card,
.card-group-md-row > *:last-child > .card .card-header,
.card-group-md-row > *:last-child > .card .card-img-top,
.card-group-md-row > *:last-child > .card .card-footer,
.card-group-md-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-2 > *:first-child > .card,
.card-group-md-2 > *:first-child > .card .card-header,
.card-group-md-2 > *:first-child > .card .card-img-top,
.card-group-md-2 > *:first-child > .card .card-footer,
.card-group-md-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-2 > *:nth-child(2) > .card,
.card-group-md-2 > *:nth-child(2) > .card .card-header,
.card-group-md-2 > *:nth-child(2) > .card .card-img-top,
.card-group-md-2 > *:nth-child(2) > .card .card-footer,
.card-group-md-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-2 > *:last-child > .card,
.card-group-md-2 > *:last-child > .card .card-header,
.card-group-md-2 > *:last-child > .card .card-img-top,
.card-group-md-2 > *:last-child > .card .card-footer,
.card-group-md-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-3 > *:first-child > .card,
.card-group-md-3 > *:first-child > .card .card-header,
.card-group-md-3 > *:first-child > .card .card-img-top,
.card-group-md-3 > *:first-child > .card .card-footer,
.card-group-md-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-3 > *:nth-child(3) > .card,
.card-group-md-3 > *:nth-child(3) > .card .card-header,
.card-group-md-3 > *:nth-child(3) > .card .card-img-top,
.card-group-md-3 > *:nth-child(3) > .card .card-footer,
.card-group-md-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-3 > *:last-child > .card,
.card-group-md-3 > *:last-child > .card .card-header,
.card-group-md-3 > *:last-child > .card .card-img-top,
.card-group-md-3 > *:last-child > .card .card-footer,
.card-group-md-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-4 > *:first-child > .card,
.card-group-md-4 > *:first-child > .card .card-header,
.card-group-md-4 > *:first-child > .card .card-img-top,
.card-group-md-4 > *:first-child > .card .card-footer,
.card-group-md-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-4 > *:nth-child(4) > .card,
.card-group-md-4 > *:nth-child(4) > .card .card-header,
.card-group-md-4 > *:nth-child(4) > .card .card-img-top,
.card-group-md-4 > *:nth-child(4) > .card .card-footer,
.card-group-md-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-4 > *:last-child > .card,
.card-group-md-4 > *:last-child > .card .card-header,
.card-group-md-4 > *:last-child > .card .card-img-top,
.card-group-md-4 > *:last-child > .card .card-footer,
.card-group-md-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 992px) {
  .card-group-lg-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-lg-row > * {
    padding: 0;
  }
  .card-group-lg-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-lg-row > *:not(:first-child):not(:last-child),
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-row > *:first-child > .card,
.card-group-lg-row > *:first-child > .card .card-header,
.card-group-lg-row > *:first-child > .card .card-img-top,
.card-group-lg-row > *:first-child > .card .card-footer,
.card-group-lg-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-lg-row > *:last-child > .card,
.card-group-lg-row > *:last-child > .card .card-header,
.card-group-lg-row > *:last-child > .card .card-img-top,
.card-group-lg-row > *:last-child > .card .card-footer,
.card-group-lg-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-2 > *:first-child > .card,
.card-group-lg-2 > *:first-child > .card .card-header,
.card-group-lg-2 > *:first-child > .card .card-img-top,
.card-group-lg-2 > *:first-child > .card .card-footer,
.card-group-lg-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-2 > *:nth-child(2) > .card,
.card-group-lg-2 > *:nth-child(2) > .card .card-header,
.card-group-lg-2 > *:nth-child(2) > .card .card-img-top,
.card-group-lg-2 > *:nth-child(2) > .card .card-footer,
.card-group-lg-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-2 > *:last-child > .card,
.card-group-lg-2 > *:last-child > .card .card-header,
.card-group-lg-2 > *:last-child > .card .card-img-top,
.card-group-lg-2 > *:last-child > .card .card-footer,
.card-group-lg-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-3 > *:first-child > .card,
.card-group-lg-3 > *:first-child > .card .card-header,
.card-group-lg-3 > *:first-child > .card .card-img-top,
.card-group-lg-3 > *:first-child > .card .card-footer,
.card-group-lg-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-3 > *:nth-child(3) > .card,
.card-group-lg-3 > *:nth-child(3) > .card .card-header,
.card-group-lg-3 > *:nth-child(3) > .card .card-img-top,
.card-group-lg-3 > *:nth-child(3) > .card .card-footer,
.card-group-lg-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-3 > *:last-child > .card,
.card-group-lg-3 > *:last-child > .card .card-header,
.card-group-lg-3 > *:last-child > .card .card-img-top,
.card-group-lg-3 > *:last-child > .card .card-footer,
.card-group-lg-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-4 > *:first-child > .card,
.card-group-lg-4 > *:first-child > .card .card-header,
.card-group-lg-4 > *:first-child > .card .card-img-top,
.card-group-lg-4 > *:first-child > .card .card-footer,
.card-group-lg-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-4 > *:nth-child(4) > .card,
.card-group-lg-4 > *:nth-child(4) > .card .card-header,
.card-group-lg-4 > *:nth-child(4) > .card .card-img-top,
.card-group-lg-4 > *:nth-child(4) > .card .card-footer,
.card-group-lg-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-4 > *:last-child > .card,
.card-group-lg-4 > *:last-child > .card .card-header,
.card-group-lg-4 > *:last-child > .card .card-img-top,
.card-group-lg-4 > *:last-child > .card .card-footer,
.card-group-lg-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .card-group-xl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xl-row > * {
    padding: 0;
  }
  .card-group-xl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xl-row > *:not(:first-child):not(:last-child),
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-row > *:first-child > .card,
.card-group-xl-row > *:first-child > .card .card-header,
.card-group-xl-row > *:first-child > .card .card-img-top,
.card-group-xl-row > *:first-child > .card .card-footer,
.card-group-xl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xl-row > *:last-child > .card,
.card-group-xl-row > *:last-child > .card .card-header,
.card-group-xl-row > *:last-child > .card .card-img-top,
.card-group-xl-row > *:last-child > .card .card-footer,
.card-group-xl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-2 > *:first-child > .card,
.card-group-xl-2 > *:first-child > .card .card-header,
.card-group-xl-2 > *:first-child > .card .card-img-top,
.card-group-xl-2 > *:first-child > .card .card-footer,
.card-group-xl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-2 > *:nth-child(2) > .card,
.card-group-xl-2 > *:nth-child(2) > .card .card-header,
.card-group-xl-2 > *:nth-child(2) > .card .card-img-top,
.card-group-xl-2 > *:nth-child(2) > .card .card-footer,
.card-group-xl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-2 > *:last-child > .card,
.card-group-xl-2 > *:last-child > .card .card-header,
.card-group-xl-2 > *:last-child > .card .card-img-top,
.card-group-xl-2 > *:last-child > .card .card-footer,
.card-group-xl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-3 > *:first-child > .card,
.card-group-xl-3 > *:first-child > .card .card-header,
.card-group-xl-3 > *:first-child > .card .card-img-top,
.card-group-xl-3 > *:first-child > .card .card-footer,
.card-group-xl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-3 > *:nth-child(3) > .card,
.card-group-xl-3 > *:nth-child(3) > .card .card-header,
.card-group-xl-3 > *:nth-child(3) > .card .card-img-top,
.card-group-xl-3 > *:nth-child(3) > .card .card-footer,
.card-group-xl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-3 > *:last-child > .card,
.card-group-xl-3 > *:last-child > .card .card-header,
.card-group-xl-3 > *:last-child > .card .card-img-top,
.card-group-xl-3 > *:last-child > .card .card-footer,
.card-group-xl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-4 > *:first-child > .card,
.card-group-xl-4 > *:first-child > .card .card-header,
.card-group-xl-4 > *:first-child > .card .card-img-top,
.card-group-xl-4 > *:first-child > .card .card-footer,
.card-group-xl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-4 > *:nth-child(4) > .card,
.card-group-xl-4 > *:nth-child(4) > .card .card-header,
.card-group-xl-4 > *:nth-child(4) > .card .card-img-top,
.card-group-xl-4 > *:nth-child(4) > .card .card-footer,
.card-group-xl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-4 > *:last-child > .card,
.card-group-xl-4 > *:last-child > .card .card-header,
.card-group-xl-4 > *:last-child > .card .card-img-top,
.card-group-xl-4 > *:last-child > .card .card-footer,
.card-group-xl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .card-group-xxl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xxl-row > * {
    padding: 0;
  }
  .card-group-xxl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xxl-row > *:not(:first-child):not(:last-child),
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xxl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-row > *:first-child > .card,
.card-group-xxl-row > *:first-child > .card .card-header,
.card-group-xxl-row > *:first-child > .card .card-img-top,
.card-group-xxl-row > *:first-child > .card .card-footer,
.card-group-xxl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xxl-row > *:last-child > .card,
.card-group-xxl-row > *:last-child > .card .card-header,
.card-group-xxl-row > *:last-child > .card .card-img-top,
.card-group-xxl-row > *:last-child > .card .card-footer,
.card-group-xxl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-2 > *:first-child > .card,
.card-group-xxl-2 > *:first-child > .card .card-header,
.card-group-xxl-2 > *:first-child > .card .card-img-top,
.card-group-xxl-2 > *:first-child > .card .card-footer,
.card-group-xxl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-2 > *:nth-child(2) > .card,
.card-group-xxl-2 > *:nth-child(2) > .card .card-header,
.card-group-xxl-2 > *:nth-child(2) > .card .card-img-top,
.card-group-xxl-2 > *:nth-child(2) > .card .card-footer,
.card-group-xxl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-2 > *:last-child > .card,
.card-group-xxl-2 > *:last-child > .card .card-header,
.card-group-xxl-2 > *:last-child > .card .card-img-top,
.card-group-xxl-2 > *:last-child > .card .card-footer,
.card-group-xxl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-3 > *:first-child > .card,
.card-group-xxl-3 > *:first-child > .card .card-header,
.card-group-xxl-3 > *:first-child > .card .card-img-top,
.card-group-xxl-3 > *:first-child > .card .card-footer,
.card-group-xxl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-3 > *:nth-child(3) > .card,
.card-group-xxl-3 > *:nth-child(3) > .card .card-header,
.card-group-xxl-3 > *:nth-child(3) > .card .card-img-top,
.card-group-xxl-3 > *:nth-child(3) > .card .card-footer,
.card-group-xxl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-3 > *:last-child > .card,
.card-group-xxl-3 > *:last-child > .card .card-header,
.card-group-xxl-3 > *:last-child > .card .card-img-top,
.card-group-xxl-3 > *:last-child > .card .card-footer,
.card-group-xxl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-4 > *:first-child > .card,
.card-group-xxl-4 > *:first-child > .card .card-header,
.card-group-xxl-4 > *:first-child > .card .card-img-top,
.card-group-xxl-4 > *:first-child > .card .card-footer,
.card-group-xxl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-4 > *:nth-child(4) > .card,
.card-group-xxl-4 > *:nth-child(4) > .card .card-header,
.card-group-xxl-4 > *:nth-child(4) > .card .card-img-top,
.card-group-xxl-4 > *:nth-child(4) > .card .card-footer,
.card-group-xxl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-4 > *:last-child > .card,
.card-group-xxl-4 > *:last-child > .card .card-header,
.card-group-xxl-4 > *:last-child > .card .card-img-top,
.card-group-xxl-4 > *:last-child > .card .card-footer,
.card-group-xxl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
.card-group-row {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
  margin: 0;
}
.card-group-row > * {
  padding: 0;
}
.card-group-row > * > .card {
  height: 100%;
  box-shadow: none;
  border-radius: 0;
}
.card-group-row > *:not(:first-child):not(:last-child),
.card-group-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-bottom {
  border-radius: 0;
}
.card-group-row > *:first-child > .card {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.card-group-row > *:first-child > .card,
.card-group-row > *:first-child > .card .card-header,
.card-group-row > *:first-child > .card .card-img-top,
.card-group-row > *:first-child > .card .card-footer,
.card-group-row > *:first-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.card-group-row > *:last-child > .card {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.card-group-row > *:last-child > .card,
.card-group-row > *:last-child > .card .card-header,
.card-group-row > *:last-child > .card .card-img-top,
.card-group-row > *:last-child > .card .card-footer,
.card-group-row > *:last-child > .card .card-img-bottom {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.card-group-row > * + * > .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}
.card-group-row .card-divider {
  border-left-width: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-group-2 > *:first-child > .card,
.card-group-2 > *:first-child > .card .card-header,
.card-group-2 > *:first-child > .card .card-img-top,
.card-group-2 > *:first-child > .card .card-footer,
.card-group-2 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}
.card-group-2 > *:nth-child(2) > .card,
.card-group-2 > *:nth-child(2) > .card .card-header,
.card-group-2 > *:nth-child(2) > .card .card-img-top,
.card-group-2 > *:nth-child(2) > .card .card-footer,
.card-group-2 > *:nth-child(2) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}
.card-group-2 > *:last-child > .card,
.card-group-2 > *:last-child > .card .card-header,
.card-group-2 > *:last-child > .card .card-img-top,
.card-group-2 > *:last-child > .card .card-footer,
.card-group-2 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-3 > *:first-child > .card,
.card-group-3 > *:first-child > .card .card-header,
.card-group-3 > *:first-child > .card .card-img-top,
.card-group-3 > *:first-child > .card .card-footer,
.card-group-3 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}
.card-group-3 > *:nth-child(3) > .card,
.card-group-3 > *:nth-child(3) > .card .card-header,
.card-group-3 > *:nth-child(3) > .card .card-img-top,
.card-group-3 > *:nth-child(3) > .card .card-footer,
.card-group-3 > *:nth-child(3) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}
.card-group-3 > *:last-child > .card,
.card-group-3 > *:last-child > .card .card-header,
.card-group-3 > *:last-child > .card .card-img-top,
.card-group-3 > *:last-child > .card .card-footer,
.card-group-3 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-4 > *:first-child > .card,
.card-group-4 > *:first-child > .card .card-header,
.card-group-4 > *:first-child > .card .card-img-top,
.card-group-4 > *:first-child > .card .card-footer,
.card-group-4 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}
.card-group-4 > *:nth-child(4) > .card,
.card-group-4 > *:nth-child(4) > .card .card-header,
.card-group-4 > *:nth-child(4) > .card .card-img-top,
.card-group-4 > *:nth-child(4) > .card .card-footer,
.card-group-4 > *:nth-child(4) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}
.card-group-4 > *:last-child > .card,
.card-group-4 > *:last-child > .card .card-header,
.card-group-4 > *:last-child > .card .card-img-top,
.card-group-4 > *:last-child > .card .card-footer,
.card-group-4 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

/*------------------------------------
  Circles Chart
-------------------------------------*/
.circles-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-left: auto;
  margin-right: auto;
}

.circles-chart-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 2rem 2rem;
  transform: translate(0, -50%);
}

/*------------------------------------
  Close Button
------------------------------------*/
.btn-close:focus {
  box-shadow: none;
}

.btn-close-light {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center;
}

/*------------------------------------
  Col Divider
------------------------------------*/
@media (min-width: 576px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

@media (min-width: 768px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

@media (min-width: 992px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

@media (min-width: 1200px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

@media (min-width: 1400px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}
.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
}

.col-divider-rotated > *:not(:first-child)::before {
  transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}
.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 1.5rem);
  height: 0.0625rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
  transform: translateX(-50%);
}
.col-divider > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.col-divider > *:first-child {
  padding-top: 0;
}
.col-divider > *:last-child {
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    transform: translateX(-50%);
  }
  .col-sm-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-sm-divider > *:first-child {
    padding-top: 0;
  }
  .col-sm-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    transform: translateX(-50%);
  }
  .col-md-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-md-divider > *:first-child {
    padding-top: 0;
  }
  .col-md-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    transform: translateX(-50%);
  }
  .col-lg-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-lg-divider > *:first-child {
    padding-top: 0;
  }
  .col-lg-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 1199.98px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    transform: translateX(-50%);
  }
  .col-xl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xl-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 1399.98px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: "";
    transform: translateX(-50%);
  }
  .col-xxl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xxl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xxl-divider > *:last-child {
    padding-bottom: 0;
  }
}

.col-divider > *:not(:first-child) {
  position: relative;
}
.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
}
.col-divider > *,
.col-divider > *:first-child,
.col-divider > *:last-child {
  padding-top: 0;
  padding-bottom: 0;
}

.col-divider-light > *:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Devices
------------------------------------*/
.devices {
  max-width: 1140px;
  position: relative;
  overflow: hidden;
  padding: 0 0.75rem 7rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.devices .device-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: 5rem;
  margin-bottom: 5rem;
}
@media (max-width: 991.98px) {
  .devices .device-mobile {
    display: none;
  }
}
.devices .device-browser {
  margin-left: auto;
  margin-right: 5rem;
}
@media (max-width: 991.98px) {
  .devices .device-mobile {
    margin-right: 0.5rem;
  }
  .devices .device-browser {
    margin-right: auto;
  }
}

.devices-shadow-none .device-mobile-frame,
.devices-shadow-none .device-browser-frame {
  box-shadow: none;
}

.devices-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  grid-gap: 1rem;
}
@media (max-width: 991.98px) {
  .devices-wrap {
    display: none;
  }
}

.devices-rotated {
  transform: rotate(-22deg);
}
.devices-rotated .devices-rotated-body {
  transform: translate3d(15%, 0, 0);
}

.devices-top-start-50 {
  position: absolute;
  top: 0;
  left: 50%;
}

/*------------------------------------
  Mobile Device
------------------------------------*/
.device-mobile {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 15rem;
  height: auto;
}

.device-mobile-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 2rem;
  padding: 0.3125rem;
}

.device-mobile-img {
  max-width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

/*------------------------------------
  Tablet Devices
------------------------------------*/
.device-tablet {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
}

.device-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.device-vertical-tablet {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 30rem;
  height: auto;
}

.device-vertical-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-vertical-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

/*------------------------------------
  Browser Device
------------------------------------*/
.device-browser {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.device-browser-frame {
  background: #fff;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-img {
  max-width: 100%;
  height: auto;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-header {
  position: relative;
  display: flex;
  max-width: 50rem;
  background: #fff;
  border-bottom: 0.0625rem solid #f1f3f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 6.5rem;
}

.device-browser-header-btn-list {
  display: flex;
  grid-gap: 0.25rem;
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 1rem;
}

.device-browser-header-btn-list-btn {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-radius: 50%;
}

.device-browser-header-browser-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #677788;
  background-color: #e7eaf3;
  font-size: 0.5rem;
  border-radius: 0.125rem;
}

.device-browser-lg {
  width: 60rem;
}
.device-browser-lg .device-browser-header {
  max-width: 60rem;
}

/*------------------------------------
  Dropdown
------------------------------------*/
.dropdown-menu {
  box-shadow: 0 0.75rem 1rem rgba(189, 197, 209, 0.3);
  margin-top: 0.3125rem;
}
.dropdown-menu .dropdown-item.dropdown-toggle::after {
  transform: rotate(-90deg);
}

.dropdown-item {
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}
.dropdown-item:not(:last-child) {
  margin-bottom: 0.25rem;
}
.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}
.dropdown-item.active:not(:focus):not(:active), .dropdown-item.active:active:focus {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  width: 1.5rem;
  color: #677788;
}

.dropdown-toggle {
  position: relative;
  display: flex;
  align-items: center;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  margin-left: auto;
  padding-left: 1.25rem;
  content: "";
}
.dropdown-toggle .dropdown-item-icon {
  width: 1.75rem;
}

.dropup .dropdown-toggle::after {
  transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  transform: rotate(-90deg);
  margin-top: 0.25rem;
}

.dropleft .dropdown-toggle::after {
  transform: rotate(90deg);
  margin-top: -0.25rem;
}

.dropdown-card {
  padding: 0;
}
.dropdown-card .card {
  box-shadow: none;
}

.dropdown-card-shopping-cart .card > .card-footer {
  border-top-width: 0.0625rem;
}

.dropdown-course-search {
  position: static !important;
}
.dropdown-course-search .dropdown-menu {
  width: 100%;
}

/*------------------------------------
  Form Check
------------------------------------*/
.form-check-label {
  font-size: 0.875rem;
  margin-top: 0.125rem;
}

.form-check .form-check-label {
  margin-top: 0;
}

label.form-control,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: 1px solid rgba(33, 50, 91, 0.1);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #677788;
}

/*------------------------------------
  Form Check Card
------------------------------------*/
.form-check-card {
  position: relative;
  padding: 1rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
}
.form-check-card .form-check-input {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  margin: 0;
}

/*------------------------------------
  Form Check Bookmark
------------------------------------*/
.form-check-bookmark .form-check-input {
  width: 0;
  height: 0;
  margin: 0;
  border: none;
}
.form-check-bookmark .form-check-bookmark-active {
  color: #f5ca99;
}
.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-default {
  display: block;
}
.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-active {
  display: none;
}
.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-default {
  display: none;
}
.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-active {
  display: block;
}

/*------------------------------------
  Form Select
------------------------------------*/
.form-select {
  cursor: pointer;
}

/*------------------------------------
  Form Switch
------------------------------------*/
.form-switch {
  position: relative;
}
.form-switch .form-check-input {
  width: 2.8125em;
  height: 1.8125em;
  border-width: 0;
  background-color: #e7eaf3;
  margin-top: -0.25em;
  margin-right: 0.75rem;
}
.form-switch .form-check-input:active {
  filter: 100%;
}

.form-check-input:checked {
  background-color: #006C7B;
}

.form-switch-between {
  align-items: center;
  padding-left: 0;
}
.form-switch-between .form-check-input {
  float: none;
  align-items: center;
  margin-left: 0.5rem;
}
.form-switch-between .form-check-label {
  cursor: inherit;
}

.form-switch-promotion {
  position: relative;
  min-width: 5rem;
}

.form-switch-promotion-container {
  position: absolute;
  top: -1.75rem;
  left: 1.25rem;
}

.form-switch-promotion-body {
  display: flex;
  align-items: center;
  margin-top: -1rem;
}

.form-switch-promotion-arrow {
  margin-right: -1.75rem;
}

.form-switch-promotion-text {
  display: block;
  margin-top: 0.3125rem;
}

/*------------------------------------
  Form Attachment
------------------------------------*/
.form-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.form-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  Form Text
------------------------------------*/
.form-text {
  margin-bottom: 0;
}

/*------------------------------------
  Form Link
------------------------------------*/
.form-link {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1rem;
}

/*------------------------------------
  Input Group Merge
------------------------------------*/
.input-group-merge {
  position: relative;
  overflow: hidden;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}
.input-group-merge .input-group-prepend,
.input-group-merge .input-group-append {
  position: absolute;
  top: 1px;
  bottom: 1px;
  z-index: 4;
  background-color: transparent;
}
.input-group-merge .input-group-prepend.input-group-text,
.input-group-merge .input-group-append.input-group-text {
  border-width: 0;
}
.input-group-merge .input-group-prepend {
  left: 1px;
}
.input-group-merge .input-group-append {
  right: 1px;
}
.input-group-merge .form-select,
.input-group-merge .form-control {
  border-width: 0;
}
.input-group-merge .form-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 3.375rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-merge .form-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 3.375rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.input-group-merge .form-select-sm,
.input-group-merge .form-control-sm {
  min-height: calc(1.5em + 0.875rem);
}
.input-group-merge .form-select-sm:not(:first-child),
.input-group-merge .form-control-sm:not(:first-child) {
  padding-left: 3.375rem;
}
.input-group-merge .form-select-sm:not(:last-child),
.input-group-merge .form-control-sm:not(:last-child) {
  padding-right: 3.375rem;
}

.input-group-merge .form-select-lg,
.input-group-merge .form-control-lg {
  min-height: calc(1.5em + 1.375rem);
}

.was-validated .input-group-merge.is-valid {
  border-color: #00c9a7;
}
.was-validated .input-group-merge.is-valid.focus {
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.was-validated .input-group-merge.is-invalid {
  border-color: #ed4c78;
}
.was-validated .input-group-merge.is-invalid.focus {
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*------------------------------------
  Input Group Break
------------------------------------*/
.input-group-down-break {
  display: block;
}
.input-group-down-break > .form-control,
.input-group-down-break > .form-select {
  width: 100%;
}
.input-group-down-break > .form-control:first-child,
.input-group-down-break > .form-select:first-child {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-down-break > .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-down-break > .form-select:last-child,
.input-group-down-break > .form-control:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}
.input-group-down-break .form-control + .form-control,
.input-group-down-break .form-control + .form-select,
.input-group-down-break .form-control-plaintext + .form-control,
.input-group-down-break .form-control-plaintext + .form-select,
.input-group-down-break .form-select + .form-control,
.input-group-down-break .form-select + .form-select {
  margin-left: 0 !important;
  margin-top: -0.0625rem;
}

@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .form-control,
.input-group-sm-down-break > .form-select {
    width: 100%;
  }
  .input-group-sm-down-break > .form-control:first-child,
.input-group-sm-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-sm-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-sm-down-break > .form-select:last-child,
.input-group-sm-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-sm-down-break .form-control + .form-control,
.input-group-sm-down-break .form-control + .form-select,
.input-group-sm-down-break .form-control-plaintext + .form-control,
.input-group-sm-down-break .form-control-plaintext + .form-select,
.input-group-sm-down-break .form-select + .form-control,
.input-group-sm-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 767.98px) {
  .input-group-md-down-break {
    display: block;
  }
  .input-group-md-down-break > .form-control,
.input-group-md-down-break > .form-select {
    width: 100%;
  }
  .input-group-md-down-break > .form-control:first-child,
.input-group-md-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-md-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-md-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-md-down-break > .form-select:last-child,
.input-group-md-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-md-down-break .form-control + .form-control,
.input-group-md-down-break .form-control + .form-select,
.input-group-md-down-break .form-control-plaintext + .form-control,
.input-group-md-down-break .form-control-plaintext + .form-select,
.input-group-md-down-break .form-select + .form-control,
.input-group-md-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 991.98px) {
  .input-group-lg-down-break {
    display: block;
  }
  .input-group-lg-down-break > .form-control,
.input-group-lg-down-break > .form-select {
    width: 100%;
  }
  .input-group-lg-down-break > .form-control:first-child,
.input-group-lg-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-lg-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-lg-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-lg-down-break > .form-select:last-child,
.input-group-lg-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-lg-down-break .form-control + .form-control,
.input-group-lg-down-break .form-control + .form-select,
.input-group-lg-down-break .form-control-plaintext + .form-control,
.input-group-lg-down-break .form-control-plaintext + .form-select,
.input-group-lg-down-break .form-select + .form-control,
.input-group-lg-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1199.98px) {
  .input-group-xl-down-break {
    display: block;
  }
  .input-group-xl-down-break > .form-control,
.input-group-xl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xl-down-break > .form-control:first-child,
.input-group-xl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xl-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-xl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xl-down-break > .form-select:last-child,
.input-group-xl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xl-down-break .form-control + .form-control,
.input-group-xl-down-break .form-control + .form-select,
.input-group-xl-down-break .form-control-plaintext + .form-control,
.input-group-xl-down-break .form-control-plaintext + .form-select,
.input-group-xl-down-break .form-select + .form-control,
.input-group-xl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1399.98px) {
  .input-group-xxl-down-break {
    display: block;
  }
  .input-group-xxl-down-break > .form-control,
.input-group-xxl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xxl-down-break > .form-control:first-child,
.input-group-xxl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-xxl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:last-child,
.input-group-xxl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xxl-down-break .form-control + .form-control,
.input-group-xxl-down-break .form-control + .form-select,
.input-group-xxl-down-break .form-control-plaintext + .form-control,
.input-group-xxl-down-break .form-control-plaintext + .form-select,
.input-group-xxl-down-break .form-select + .form-control,
.input-group-xxl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}

.input-group-add-field-delete {
  position: absolute;
  top: 0.75rem;
  right: -1.25rem;
  color: #ed4c78;
}
.input-group-add-field-delete:hover {
  color: #ea2c60;
}

/*------------------------------------
  Input Card
------------------------------------*/
.input-card {
  display: flex;
  background-color: #fff;
  padding: 0.6125rem 0.6125rem;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
}
.input-card .input-card-form {
  flex: 1 0 0%;
}
.input-card .btn {
  flex: 0 0 auto;
}
.input-card .form-control {
  border-width: 0;
}
.input-card .form-control:focus {
  box-shadow: none;
}
.input-card .input-group {
  border-width: 0;
}
.input-card .input-card-form {
  position: relative;
}
.input-card .input-card-form:not(:first-child) {
  padding-left: 1rem;
}
.input-card .input-card-form:not(:first-child)::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.0625rem;
  height: 2rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: "";
  transform: translateY(-50%);
}
.input-card .input-card-form:not(:last-child) {
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .input-card-pill {
    border-radius: 50rem;
  }
}
@media (max-width: 575.98px) {
  .input-card-sm {
    display: grid;
  }
  .input-card-sm .btn,
.input-card-sm .input-card-form {
    flex: 0 0 auto;
  }
  .input-card-sm .input-card-form {
    padding: 1rem 0 !important;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .input-card-sm .input-card-form:first-child {
    padding-top: 0 !important;
  }
  .input-card-sm .input-card-form:not(:first-child)::before {
    display: none;
  }
}
/*------------------------------------
  Labels
------------------------------------*/
.col-form-label {
  font-size: 0.875rem;
}

.form-label-secondary {
  font-size: 0.875rem;
  color: #8c98a4;
}

.form-label-link {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/*------------------------------------
  Go To
------------------------------------*/
.go-to {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 3.125rem;
  height: 3.125rem;
  background-color: rgba(113, 134, 157, 0.1);
  color: #677788;
  font-size: 1rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}
.go-to:hover, .go-to:focus:hover {
  color: #fff;
  background-color: #006C7B;
  opacity: 1;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
}

.icon-circle {
  border-radius: 50%;
}

.icon.icon-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.icon.icon-sm {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.icon.icon-lg {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon-primary {
  color: #fff;
  border-color: #006C7B;
  background-color: #006C7B;
}

.icon-secondary {
  color: #fff;
  border-color: #71869d;
  background-color: #71869d;
}

.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.icon-info {
  color: #fff;
  border-color: #09a5be;
  background-color: #09a5be;
}

.icon-warning {
  color: #000;
  border-color: #f5ca99;
  background-color: #f5ca99;
}

.icon-danger {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}

.icon-light {
  color: #000;
  border-color: #f7faff;
  background-color: #f7faff;
}

.icon-dark {
  color: #fff;
  border-color: #21325b;
  background-color: #21325b;
}

.icon-soft-primary {
  color: #006C7B;
  background: rgba(0, 108, 123, 0.1);
}

.icon-soft-secondary {
  color: #71869d;
  background: rgba(113, 134, 157, 0.1);
}

.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}

.icon-soft-info {
  color: #09a5be;
  background: rgba(9, 165, 190, 0.1);
}

.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}

.icon-soft-danger {
  color: #ed4c78;
  background: rgba(237, 76, 120, 0.1);
}

.icon-soft-light {
  color: #f7faff;
  background: rgba(247, 250, 255, 0.1);
}

.icon-soft-dark {
  color: #21325b;
  background: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Legend Indicator
------------------------------------*/
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

/*------------------------------------
  List Comment
------------------------------------*/
.list-comment {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.list-comment:first-child {
  margin-top: -2.5rem;
}

.list-comment-item {
  margin-top: 2.5rem;
}
.list-comment-item .list-comment .list-comment-item {
  padding-left: 1rem;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.list-comment-divider .list-comment-item:not(:last-child) {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2.5rem;
}

/*------------------------------------
  List Padding
------------------------------------*/
.list-py-1 > li:not(:first-child) {
  padding-top: 0.25rem;
}
.list-py-1 > li:not(:last-child) {
  padding-bottom: 0.25rem;
}

.list-py-2 > li:not(:first-child) {
  padding-top: 0.5rem;
}
.list-py-2 > li:not(:last-child) {
  padding-bottom: 0.5rem;
}

.list-py-3 > li:not(:first-child) {
  padding-top: 1rem;
}
.list-py-3 > li:not(:last-child) {
  padding-bottom: 1rem;
}

/*------------------------------------
  List Separator
------------------------------------*/
.list-separator {
  margin-bottom: 0;
}
.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}
.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}
.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  content: "/";
  opacity: 0.4;
}
.list-separator .list-separator-link {
  color: #677788;
}
.list-separator .list-separator-link:hover {
  color: #004d57;
}

.list-separator-light .list-inline-item::after {
  color: #fff;
}
.list-separator-light .list-separator-link, .list-separator-light .list-separator-link:hover {
  color: #fff;
}
.list-separator-light .list-separator-link:hover {
  text-decoration: underline;
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.list-group-item.active .list-group-icon {
  color: #fff;
}

.list-group-icon {
  display: inline-block;
  color: #677788;
  width: 2rem;
  text-align: center;
  margin-right: 0.5rem;
}

.list-group-striped > li:nth-of-type(odd) {
  background-color: rgba(0, 108, 123, 0.1);
}

.list-group-sm .list-group-item {
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem;
}

.list-group-lg .list-group-item {
  padding: 1.5rem 1.5rem;
}
.list-group-lg .list-group-icon {
  font-size: 1.5rem;
  width: 2.5rem;
}

.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  color: #677788;
  padding-left: 2rem;
}
.list-checked-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: "";
  margin-top: 0.125rem;
}
.list-checked-item[hover]:hover {
  color: #006C7B;
}

[class*=list-checked-bg-] .list-checked-item::before,
[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23006C7B'/%3e%3c/svg%3e");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23006C7B'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23006C7B' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23006C7B'/%3e%3c/svg%3e");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-sm .list-checked-item {
  padding-left: 1.5rem;
}
.list-checked-sm .list-checked-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}
.list-checked-sm[class*=list-checked-bg-] .list-checked-item::before, .list-checked-sm[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-lg .list-checked-item {
  padding-left: 2.75rem;
}
.list-checked-lg .list-checked-item:not(:last-child) {
  margin-bottom: 1.25rem;
}
.list-checked-lg .list-checked-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}
.list-checked-lg[class*=list-checked-bg-] .list-checked-item::before, .list-checked-lg[class*=list-checked-soft-bg-] .list-checked-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  List Pointer
------------------------------------*/
.list-pointer {
  padding-left: 0;
  list-style: none;
}

.list-pointer-item {
  position: relative;
  color: #677788;
  padding-left: 1.75rem;
}
.list-pointer-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.list-pointer-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: "";
  margin-top: 0.1875rem;
}
.list-pointer-item[hover]:hover {
  color: #006C7B;
}

[class*=list-pointer-bg-] .list-pointer-item::before,
[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23006C7B' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23006C7B'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23006C7B' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23006C7B' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23006C7B'/%3e%3c/svg%3e");
}

.list-pointer-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-pointer-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2300c9a7' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-pointer-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2309a5be' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-pointer-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f5ca99' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-pointer-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23ed4c78' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-pointer-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f7faff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-pointer-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2321325b' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-pointer-sm .list-pointer-item {
  padding-left: 1.5rem;
}
.list-pointer-sm .list-pointer-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}
.list-pointer-sm[class*=list-pointer-bg-] .list-pointer-item::before, .list-pointer-sm[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-lg .list-pointer-item {
  padding-left: 2.75rem;
}
.list-pointer-lg .list-pointer-item:not(:last-child) {
  margin-bottom: 1.25rem;
}
.list-pointer-lg .list-pointer-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}
.list-pointer-lg[class*=list-pointer-bg-] .list-pointer-item::before, .list-pointer-lg[class*=list-pointer-soft-bg-] .list-pointer-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.media-viewer {
  position: relative;
  display: block;
}
.media-viewer:hover .media-viewer-icon {
  opacity: 1;
  transform: scale(1);
}

.media-viewer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media-viewer-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #006C7B;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.7);
  transition: 0.3s;
}
.media-viewer-icon:hover, .media-viewer-icon:focus {
  color: #fff;
}

.media-viewer-icon-active {
  opacity: 1;
  transform: scale(1);
}

/*------------------------------------
  Modal
------------------------------------*/
.modal-header {
  align-items: center;
  padding-bottom: 0;
}
.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}

.modal-footer {
  border-top-width: 0.0625rem;
}
.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-footer-text:last-child {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}

.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

/*------------------------------------
  Nav
------------------------------------*/
.nav-subtitle {
  display: block;
  color: #8c98a4;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.nav-link.dropdown-toggle {
  align-items: center;
}

.nav-link.active {
  color: #006C7B;
}

.nav-link.active .nav-link-svg path,
.nav-item.show .nav-link-svg path {
  fill: #006C7B;
}

.nav-title:last-child,
.nav-text:last-child {
  margin-bottom: 0;
}

.nav-icon {
  opacity: 0.7;
  flex: 0 0 1.9375rem;
}

.nav.nav-link-gray .nav-link {
  color: #677788;
}
.nav.nav-link-gray .nav-link.active, .nav.nav-link-gray .nav-link:hover {
  color: #006C7B;
}
.nav.nav-link-gray .nav-link.disabled {
  color: #bdc5d1;
}

.nav-tabs .nav-link {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 0.1875rem solid transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
}
.nav-tabs:not(.nav-vertical) .nav-link {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.nav-vertical {
  flex-direction: column;
}
.nav-vertical.nav .nav-item:not(:last-child) {
  margin-right: 0;
}
.nav-vertical.nav .nav-link {
  display: flex;
  white-space: nowrap;
}
.nav-vertical .nav-subtitle {
  padding-left: 1rem;
}
.nav-vertical.nav-tabs {
  border-bottom-width: 0;
}
.nav-vertical.nav-tabs .nav-link {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left: 0.1875rem solid transparent;
  margin-bottom: 0;
}
.nav-vertical.nav-tabs .nav-subtitle {
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}
.nav-vertical.nav-tabs .nav-link.active,
.nav-vertical.nav-tabs .nav-item.show .nav-link {
  border-color: #006C7B;
}
.nav-vertical.nav.nav-tabs .nav-collapse .nav-link {
  padding-left: 3rem;
}
.nav-vertical.nav.nav-tabs .nav-collapse .nav-collapse .nav-link {
  padding-left: 4.5rem;
}
.nav-vertical.nav-pills .nav-link.active,
.nav-vertical.nav-pills .show > .nav-link {
  color: #21325b;
  background-color: transparent;
}
.nav-vertical.nav-pills .nav-link {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
}
.nav-vertical.nav-pills .nav-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}
.nav-vertical.nav-pills .nav-link:hover, .nav-vertical.nav-pills .nav-link:focus {
  color: #21325b;
  background-color: rgba(189, 197, 209, 0.2);
}
.nav-vertical.nav-pills .nav-link[aria-expanded=true] {
  color: #006C7B;
}
.nav-vertical.nav-pills .nav-collapse {
  position: relative;
  padding-left: 1.9375rem;
}
.nav-vertical.nav-pills .nav-collapse::before {
  position: absolute;
  top: 0;
  left: 1.3125rem;
  width: 0.125rem;
  height: calc(100% - 0.25rem);
  content: "";
  background-color: rgba(33, 50, 91, 0.1);
}
.nav-vertical.nav-pills .nav-link.dropdown-toggle {
  display: flex;
  width: 100%;
}

.nav-pills .nav-item {
  margin: 0.25rem 0.25rem;
}
.nav-pills .nav-link {
  padding: 1rem 1rem;
}
.nav-pills .nav-link:hover {
  color: #fff;
}
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  border-color: transparent;
}

.nav-pills-shadow .nav-link.active {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.5rem;
}
.nav-segment:not(.nav-fill) {
  display: inline-flex;
}
.nav-segment .nav-link {
  color: #677788;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.nav-segment .nav-link:hover {
  color: #006C7B;
}
.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(140, 152, 164, 0.25);
}
.nav-segment .nav-link.disabled {
  color: #bdc5d1;
}
.nav-segment.nav-pills {
  border-radius: 50rem;
}
.nav-segment.nav-pills .nav-link {
  border-radius: 50rem;
}

.nav-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.nav-light .nav-link.active, .nav-light .nav-link:hover {
  color: #fff;
}
.nav-light.nav-pills .nav-link:hover {
  background-color: rgba(189, 197, 209, 0.2);
}
.nav-light.nav-pills .nav-link.active, .nav-light.nav-pills .nav-link:hover {
  color: #fff;
}

.nav-sm .nav-link {
  font-size: 0.9375rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.nav-sm.nav-pills .nav-subtitle {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.nav-sm.nav-pills .nav-link {
  padding: 0.5rem 0.75rem;
}

.nav-lg .nav-link {
  font-size: 1rem;
}
.nav-lg.nav-pills .nav-link {
  padding: 1.5rem 1.5rem;
}

.nav-link-badge {
  margin-left: auto;
}

/*------------------------------------
  Navbar
------------------------------------*/
.navbar {
  display: block;
  z-index: 99;
}

.navbar-collapse {
  align-items: flex-start;
}

.navbar-nav {
  flex-grow: 1;
}

.navbar-shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar-height {
  height: 3.875rem;
}

.navbar.navbar-scrolled.navbar-light {
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}
.navbar.navbar-scrolled.navbar-dark {
  background-color: #21325b;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand-logo {
  width: 100%;
  min-width: 7.5rem;
  max-width: 7.5rem;
}

.navbar-brand-collapsed,
.navbar-brand-on-scroll {
  display: none;
}

.navbar-scrolled .navbar-brand-default {
  display: none;
}
.navbar-scrolled .navbar-brand-on-scroll {
  display: inline-block;
}

.navbar-nav-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  align-items: center;
}
.navbar-nav-wrap .navbar-toggler {
  margin-left: auto;
}

.navbar-nav-wrap-secondary-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler .navbar-toggler-text {
  color: #21325b;
}
.navbar-toggler .navbar-toggler-default {
  display: flex;
}
.navbar-toggler .navbar-toggler-toggled {
  display: none;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-default {
  display: none;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-toggled {
  display: flex;
}
.navbar-topbar .navbar-toggler {
  margin-left: 0;
  font-size: 0.875rem;
}

.navbar-topbar .js-mega-menu {
  z-index: 11;
}

.navbar-topbar-toggler {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin: 0.75rem 0.75rem 0 0.75rem;
  padding-bottom: 0.75rem;
}

@keyframes navbar-topbar-collapse-scale-up {
  0% {
    transform: scale(0.5);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 0%;
  }
}
.navbar-topbar-collapse .navbar-toggler {
  display: flex;
  margin-left: auto;
}

.navbar .navbar-nav .nav-link {
  padding: 1rem 0.75rem;
}

.navbar .nav-item:hover > .nav-link {
  color: #004d57;
}

.navbar .dropdown-menu {
  border-top: 0.1875rem solid #006C7B;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar .dropdown-menu::before {
  position: absolute;
  top: -1rem;
  display: block;
  left: 0;
  width: 100%;
  height: 1.75rem;
  content: "";
}

.navbar-dropdown-menu-inner {
  padding: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-media-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}
.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}
.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #006C7B;
}
.navbar-dropdown-menu-media-link.disabled {
  pointer-events: none;
}
.navbar-dropdown-menu-media-link + .navbar-dropdown-menu-media-link {
  margin-top: 0.5rem;
}

.navbar-dropdown-menu-media-title {
  color: #1e2022;
  font-weight: 600;
}

.navbar-dropdown-menu-media-desc {
  color: #677788;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item {
  position: relative;
}
.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  top: -0.75rem;
  right: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: "";
}

.navbar-dropdown-menu-promo-item {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  margin: 0.75rem 0.75rem;
}
.navbar-dropdown-menu-promo-item ~ .navbar-dropdown-menu-promo-item {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.navbar .navbar-dropdown-menu-borderless {
  border-top-width: 0;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-promo .navbar-dropdown-menu-promo-item {
  border-top: none;
}

.navbar-dropdown-menu-promo-link {
  display: block;
  height: 100%;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}
.navbar-dropdown-menu-promo-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}
.navbar-dropdown-menu-promo-link.active .navbar-dropdown-menu-media-title {
  color: #006C7B;
}
.navbar-dropdown-menu-promo-link.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}
.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #006C7B;
}

.navbar-dropdown-menu-banner {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-banner-content {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
  transform: translate(0, -50%);
}

.navbar-dropdown-menu-shop-banner {
  position: relative;
  background-color: #f7faff;
  padding-left: 10rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-shop-banner-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10rem;
}

.navbar.navbar-vertical .navbar-nav .nav-subtitle,
.navbar.navbar-vertical .navbar-nav .nav-link {
  padding: 0.3125rem 1.5rem;
}
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical {
  padding-right: 0;
  padding-left: 0;
}
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-subtitle {
  border-left-color: transparent;
}
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link.active,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-item.show .nav-link {
  border-color: #006C7B;
}

.navbar-input-group {
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
.navbar-input-group input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type=search]::-webkit-search-decoration,
.navbar-input-group input[type=search]::-webkit-search-cancel-button,
.navbar-input-group input[type=search]::-webkit-search-results-button,
.navbar-input-group input[type=search]::-webkit-search-results-decoration {
  display: none;
}

.navbar-vertical .card .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  padding-right: 0;
  padding-left: 1.8125rem;
  margin-left: -2rem;
}
.navbar-vertical .card .nav-tabs .nav-link:not(.active) {
  border-color: transparent;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-absolute-sm-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-absolute-md-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-absolute-lg-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-absolute-xl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-absolute-xxl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-absolute-md-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .navbar-absolute-top .navbar-absolute-top-scroller,
.navbar-absolute-sm-top .navbar-absolute-top-scroller,
.navbar-absolute-lg-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar,
.navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar,
.navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
.navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
.navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-absolute-sm-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-absolute-lg-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .navbar-absolute-xl-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Sticky Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-sticky-sm-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-sticky-md-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-sticky-lg-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-sticky-xl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-sticky-xxl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-sticky-md-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .navbar-sticky-top .navbar-sticky-top-scroller,
.navbar-sticky-sm-top .navbar-sticky-top-scroller,
.navbar-sticky-lg-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar,
.navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar,
.navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
.navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
.navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-sticky-sm-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
.navbar-sticky-lg-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .navbar-sticky-xl-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
/*------------------------------------
  Navbar Expand
------------------------------------*/
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-toggler {
    order: 1;
  }
  .navbar-expand-sm .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-sm .navbar-collapse {
    width: auto;
    order: 2;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    order: 3;
  }
  .navbar-expand-sm:not(.navbar-vertical) .navbar-nav {
    align-items: center;
  }
  .navbar-expand-sm .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-sm .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-sm .hs-sub-menu.dropdown-menu,
.navbar-expand-sm .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-sm .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-topbar .navbar-nav {
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-nav {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-end .navbar-sticky-top-scroller, .navbar-expand-sm.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu, .navbar-expand-sm.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-sm.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-sm .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar {
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    order: 1;
  }
  .navbar-expand-md .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-md .navbar-collapse {
    width: auto;
    order: 2;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    order: 3;
  }
  .navbar-expand-md:not(.navbar-vertical) .navbar-nav {
    align-items: center;
  }
  .navbar-expand-md .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-md .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-md .hs-sub-menu.dropdown-menu,
.navbar-expand-md .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-md .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-md .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-md.navbar-end .navbar-topbar .navbar-nav {
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-md.navbar-end .navbar-nav {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-md.navbar-end .navbar-sticky-top-scroller, .navbar-expand-md.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-md.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu, .navbar-expand-md.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-md.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-md .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar {
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    order: 1;
  }
  .navbar-expand-lg .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    width: auto;
    order: 2;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    order: 3;
  }
  .navbar-expand-lg:not(.navbar-vertical) .navbar-nav {
    align-items: center;
  }
  .navbar-expand-lg .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-lg .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-lg .hs-sub-menu.dropdown-menu,
.navbar-expand-lg .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-lg .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-topbar .navbar-nav {
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-nav {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-end .navbar-sticky-top-scroller, .navbar-expand-lg.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu, .navbar-expand-lg.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-lg.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar {
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-toggler {
    order: 1;
  }
  .navbar-expand-xl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xl .navbar-collapse {
    width: auto;
    order: 2;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    order: 3;
  }
  .navbar-expand-xl:not(.navbar-vertical) .navbar-nav {
    align-items: center;
  }
  .navbar-expand-xl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xl .hs-sub-menu.dropdown-menu,
.navbar-expand-xl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-topbar .navbar-nav {
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-nav {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-end .navbar-sticky-top-scroller, .navbar-expand-xl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu, .navbar-expand-xl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-xl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar {
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-toggler {
    order: 1;
  }
  .navbar-expand-xxl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xxl .navbar-collapse {
    width: auto;
    order: 2;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    order: 3;
  }
  .navbar-expand-xxl:not(.navbar-vertical) .navbar-nav {
    align-items: center;
  }
  .navbar-expand-xxl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xxl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xxl .hs-sub-menu.dropdown-menu,
.navbar-expand-xxl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xxl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-topbar .navbar-nav {
    flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-nav {
    justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-end .navbar-sticky-top-scroller, .navbar-expand-xxl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xxl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu, .navbar-expand-xxl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand-xxl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xxl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar {
    flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo {
    display: flex;
    flex-flow: row wrap;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: "";
  }
}

.navbar-expand .navbar-toggler {
  order: 1;
}
.navbar-expand .navbar-toggler-wrapper {
  display: none;
}
.navbar-expand .navbar-collapse {
  width: auto;
  order: 2;
}
.navbar-expand .navbar-nav-wrap-secondary-content {
  order: 3;
}
.navbar-expand:not(.navbar-vertical) .navbar-nav {
  align-items: center;
}
.navbar-expand .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.navbar-expand .nav-item .nav-item {
  margin-right: 0;
}
.navbar-expand .hs-sub-menu.dropdown-menu,
.navbar-expand .hs-sub-menu.dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}
.navbar-expand .dropdown-menu {
  margin-top: 0.5rem;
}
.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -0.6875rem;
  margin-left: 0.5rem;
}
.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
  top: 0%;
  left: 100%;
}
.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
  top: 0;
  left: -1rem;
  width: 1rem;
  height: 100%;
}
.navbar-expand .navbar-topbar .navbar-toggler {
  margin-left: 0;
}
.navbar-expand .navbar-topbar .nav-link {
  font-size: 0.9375rem;
  padding: 0.5rem 0.75rem;
}
.navbar-expand .navbar-topbar .nav-item:first-child .nav-link {
  padding-left: 0;
}
.navbar-expand .navbar-topbar .nav-item:last-child .nav-link {
  padding-right: 0;
}
.navbar-expand.navbar-end .navbar-topbar .navbar-nav {
  flex-grow: 1;
  margin-left: 0;
}
.navbar-expand.navbar-end .navbar-nav {
  justify-content: flex-end;
  margin-left: auto;
}
.navbar-expand.navbar-end .navbar-sticky-top-scroller, .navbar-expand.navbar-end .navbar-absolute-top-scroller {
  margin-left: auto;
}
.navbar-expand.navbar-floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2.5rem;
}
.navbar-expand.navbar-floating.navbar-scrolled {
  position: fixed;
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-top: 0;
}
.navbar-expand.navbar-floating.navbar-scrolled .navbar-floating-nav {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-expand.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
  box-shadow: none;
}
.navbar-expand.navbar-floating .navbar-floating-nav {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-expand.navbar-floating .dropdown-menu, .navbar-expand.navbar-floating .dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}
.navbar-expand.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand.navbar-floating .dropdown-menu .hs-mega-menu {
  margin-top: 0;
}
.navbar-expand .navbar-floating-nav {
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-expand.navbar-vertical.navbar-sidebar {
  flex-flow: column;
  overflow-y: scroll;
  height: 100%;
  max-height: 100vh;
}
.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.navbar-expand.navbar-vertical.navbar-sidebar .navbar-collapse {
  width: 100%;
  display: block !important;
}
.navbar-expand.navbar-vertical.navbar-sidebar .navbar-nav {
  display: block;
  flex-direction: column;
  align-items: flex-start;
}
.navbar-expand .navbar-dropdown-menu-promo {
  display: flex;
  flex-flow: row wrap;
}
.navbar-expand .navbar-dropdown-menu-promo-item {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
}
.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
  position: relative;
}
.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.75rem;
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
  height: 100%;
  content: "";
}

.navbar-expand .navbar-nav-wrap-secondary-content {
  margin-left: auto;
}
.navbar-expand .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
  margin-left: 0.5rem;
}
.navbar-expand .navbar-collapse {
  background-color: #fff;
}
.navbar-expand .navbar-nav {
  padding: 1rem 1rem;
}
.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding: 0.5rem 0;
}
.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
  min-width: 100% !important;
}
.navbar-expand .navbar-topbar-collapse {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: auto;
  max-height: calc(100% - 3rem);
  overflow-y: scroll;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}
.navbar-expand .navbar-topbar-collapse.collapsing,
.navbar-expand .navbar-topbar-collapse.show {
  animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  position: relative;
  box-shadow: none;
  border-top-width: 0;
  margin-top: 0;
  border-radius: 0;
  padding-left: 1rem;
}
.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
  position: absolute;
  top: 0;
  left: 0.4375rem;
  width: 0.125rem;
  height: calc(100% - 0.25rem);
  content: "";
  background-color: rgba(33, 50, 91, 0.1);
}
.navbar-expand .navbar-dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-dropdown-sub-menu {
  position: static;
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}
.navbar-expand.navbar-dark .navbar-nav .dropdown-menu {
  border-left-width: 0;
}
.navbar-expand .hs-sub-menu-opened > .dropdown-toggle::after {
  transform: rotate(180deg);
}
.navbar-expand .dropdown-menu .dropdown-item.dropdown-toggle::after {
  transform: rotate(0deg);
}
.navbar-expand .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
  transform: rotate(180deg);
}
.navbar-expand .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
  transform: rotate(180deg);
}
.navbar-expand .hs-mega-menu .hs-mega-menu,
.navbar-expand .hs-mega-menu .hs-sub-menu,
.navbar-expand .hs-menu-vertical .hs-mega-menu,
.navbar-expand .hs-menu-vertical .hs-sub-menu,
.navbar-expand .hs-sub-menu .hs-mega-menu,
.navbar-expand .hs-sub-menu .hs-sub-menu {
  left: 0;
}
.navbar-expand .navbar-topbar {
  margin-bottom: 0.4375rem;
}
.navbar-expand.navbar-dark .navbar-nav,
.navbar-expand.navbar-dark .navbar-topbar-collapse {
  background-color: #21325b;
}
.navbar-expand.navbar-dark .navbar-topbar-toggler {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.navbar-expand.navbar-vertical .navbar-nav {
  padding: 0;
}
.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  top: -0.375rem;
}
.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
  position: relative;
}
.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  bottom: -0.375rem;
  right: 0;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: "";
}
.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
  border-top-width: 0;
}
.navbar-expand .navbar-absolute-top-inner {
  background-color: #fff;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-sm .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-sm .navbar-nav .nav-subtitle,
.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-sm .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-sm .navbar-topbar-collapse.collapsing,
.navbar-expand-sm .navbar-topbar-collapse.show {
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-sm .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .dropdown-item.dropdown-toggle::after {
    transform: rotate(0deg);
  }
  .navbar-expand-sm .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-sm .hs-mega-menu .hs-mega-menu,
.navbar-expand-sm .hs-mega-menu .hs-sub-menu,
.navbar-expand-sm .hs-menu-vertical .hs-mega-menu,
.navbar-expand-sm .hs-menu-vertical .hs-sub-menu,
.navbar-expand-sm .hs-sub-menu .hs-mega-menu,
.navbar-expand-sm .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-sm .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav,
.navbar-expand-sm.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-sm.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-sm.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-sm .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-md .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-md .navbar-nav .nav-subtitle,
.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-md .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-md .navbar-topbar-collapse.collapsing,
.navbar-expand-md .navbar-topbar-collapse.show {
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-md .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-md.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .dropdown-item.dropdown-toggle::after {
    transform: rotate(0deg);
  }
  .navbar-expand-md .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-md .hs-mega-menu .hs-mega-menu,
.navbar-expand-md .hs-mega-menu .hs-sub-menu,
.navbar-expand-md .hs-menu-vertical .hs-mega-menu,
.navbar-expand-md .hs-menu-vertical .hs-sub-menu,
.navbar-expand-md .hs-sub-menu .hs-mega-menu,
.navbar-expand-md .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-md .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-md.navbar-dark .navbar-nav,
.navbar-expand-md.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-md.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-md.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-md .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-lg .navbar-nav .nav-subtitle,
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-lg .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .navbar-topbar-collapse.collapsing,
.navbar-expand-lg .navbar-topbar-collapse.show {
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-lg .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .dropdown-item.dropdown-toggle::after {
    transform: rotate(0deg);
  }
  .navbar-expand-lg .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-lg .hs-mega-menu .hs-mega-menu,
.navbar-expand-lg .hs-mega-menu .hs-sub-menu,
.navbar-expand-lg .hs-menu-vertical .hs-mega-menu,
.navbar-expand-lg .hs-menu-vertical .hs-sub-menu,
.navbar-expand-lg .hs-sub-menu .hs-mega-menu,
.navbar-expand-lg .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-lg .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav,
.navbar-expand-lg.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-lg.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-lg.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-lg .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xl .navbar-nav .nav-subtitle,
.navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .navbar-topbar-collapse.collapsing,
.navbar-expand-xl .navbar-topbar-collapse.show {
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    transform: rotate(0deg);
  }
  .navbar-expand-xl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xl .hs-mega-menu .hs-mega-menu,
.navbar-expand-xl .hs-mega-menu .hs-sub-menu,
.navbar-expand-xl .hs-menu-vertical .hs-mega-menu,
.navbar-expand-xl .hs-menu-vertical .hs-sub-menu,
.navbar-expand-xl .hs-sub-menu .hs-mega-menu,
.navbar-expand-xl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav,
.navbar-expand-xl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xxl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xxl .navbar-nav .nav-subtitle,
.navbar-expand-xxl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xxl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xxl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xxl .navbar-topbar-collapse.collapsing,
.navbar-expand-xxl .navbar-topbar-collapse.show {
    animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - 0.25rem);
    content: "";
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xxl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xxl .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    transform: rotate(0deg);
  }
  .navbar-expand-xxl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar-expand-xxl .hs-mega-menu .hs-mega-menu,
.navbar-expand-xxl .hs-mega-menu .hs-sub-menu,
.navbar-expand-xxl .hs-menu-vertical .hs-mega-menu,
.navbar-expand-xxl .hs-menu-vertical .hs-sub-menu,
.navbar-expand-xxl .hs-sub-menu .hs-mega-menu,
.navbar-expand-xxl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xxl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav,
.navbar-expand-xxl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xxl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xxl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: "";
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xxl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

/*------------------------------------
  Navbar Sidebar
------------------------------------*/
.navbar-sidebar-aside-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 576px) {
  .navbar-sidebar-aside-sm .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 768px) {
  .navbar-sidebar-aside-md .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 992px) {
  .navbar-sidebar-aside-lg .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 1200px) {
  .navbar-sidebar-aside-xl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
@media (min-width: 1400px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}
.navbar-sidebar-aside .navbar-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17rem;
  padding: 0;
}
.navbar-sidebar-aside .navbar-sidebar .navbar-collapse {
  width: 100%;
}
.navbar-sidebar-aside .navbar-sidebar .navbar-brand-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 17rem;
  height: 5rem;
  background-color: #fff;
  z-index: 99;
  padding: 1.25rem 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}
.navbar-sidebar-aside .navbar-sidebar .navbar-brand {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
}
.navbar-sidebar-aside .navbar-sidebar .navbar-nav {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.navbar-sidebar-aside .navbar-sidebar .navbar-sidebar-aside-body {
  padding-top: 6.25rem;
}
.navbar-sidebar-aside .navbar-sidebar-aside-content {
  margin-left: 17rem;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside .navbar-sidebar .navbar-brand-badge {
  display: none;
}
.navbar-sidebar-aside .nav-segment {
  margin: 1.25rem 1.5rem;
}

@media (max-width: 575.98px) {
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-sm .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-md .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-lg .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
@media (max-width: 1399.98px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xxl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}
/*------------------------------------
  Navbar Skins
------------------------------------*/
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #677788;
}
.navbar-light .navbar-toggler {
  color: #677788;
  border-color: rgba(33, 50, 91, 0.1);
}
.navbar-light .navbar-nav .nav-link {
  color: #677788;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #006C7B;
}
.navbar-light .navbar-nav .nav-link.active .dropdown-item-icon {
  color: #006C7B;
  opacity: 1;
}

.navbar-dark .navbar-nav .nav-item:hover .nav-link, .navbar-dark .navbar-nav .nav-item:hover .nav-link:hover, .navbar-dark .navbar-nav .nav-item:hover .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-toggler-text {
  color: #fff;
}
.navbar-dark .nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
}
.navbar-dark.navbar-fullscreen.navbar-expand .navbar-nav {
  background-color: transparent;
}
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:hover,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:focus {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Navbar Helpers
------------------------------------*/
.navbar-invisible {
  display: none;
}

.navbar-moved-up {
  transform: translate3d(0, -100%, 0);
}

.navbar-faded {
  opacity: 0;
  visibility: hidden;
}

.navbar-section-hidden {
  position: relative;
}

.navbar[data-hs-header-options*=fixMoment] {
  transition: 0.3s ease;
}

.navbar.navbar-untransitioned {
  transition: none;
}

.navbar.navbar-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}
.navbar.navbar-scrolled .navbar-topbar {
  display: none;
}

.navbar-fix-top {
  position: fixed;
}

.navbar.navbar-fix-top[data-hs-header-options*=effectCompensation] {
  transition: none;
}

/*------------------------------------
  Fullscreen
------------------------------------*/
.navbar-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-fullscreen .navbar-collapse {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-fullscreen .navbar-collapse.show {
  opacity: 1;
  visibility: visible;
  background-color: rgba(33, 50, 91, 0.9);
}

.navbar-fullscreen .navbar-toggler {
  display: block;
  z-index: 999;
}

.navbar-fullscreen .navbar-collapse .container, .navbar-fullscreen .navbar-collapse .container > *, .navbar-fullscreen .navbar-collapse .container > * > * {
  height: 100%;
}

.navbar-fullscreen .navbar-nav {
  display: block;
  flex-direction: column;
  padding: 0;
}
.navbar-fullscreen .navbar-nav .nav-item .nav-item .nav-link {
  font-size: 1rem;
}
.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  border-left: 0.1875rem solid rgba(255, 255, 255, 0.4);
}
.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  padding-left: 1rem;
}
.navbar-fullscreen .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.5rem;
}
.navbar-fullscreen .navbar-nav > .nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.navbar-fullscreen .navbar-nav .dropdown-item, .navbar-fullscreen .navbar-nav .dropdown-item.active {
  color: #fff;
}

.navbar-fullscreen .navbar-fullscreen-scroller {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 12.5vh;
}
.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}
.navbar-fullscreen .navbar-fullscreen-scroller:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*------------------------------------
  Offcanvas
------------------------------------*/
.offcanvas-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.offcanvas.offcanvas-navbar-search {
  bottom: auto;
  height: auto;
}

/*------------------------------------
  Page Header
------------------------------------*/
.page-header {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}

.page-header-title {
  margin-bottom: 0.25rem;
}

.page-header-text {
  margin-bottom: 0.25rem;
}

.page-header-tabs {
  margin-bottom: -2rem;
  border-bottom-width: 0;
}
.hs-nav-scroller-horizontal .page-header-tabs {
  margin-bottom: -2rem;
}

.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}

/*------------------------------------
  Page Preloader
------------------------------------*/
.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.page-preloader-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-item:not(:last-child) {
  margin-right: 0.25rem;
}

.page-link {
  min-width: 2.25rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.3125rem;
}

/*------------------------------------
  Popover
------------------------------------*/
.popover {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.popover-header {
  font-size: 1.25rem;
  font-weight: 600;
}

/*------------------------------------
  Profile Cover
------------------------------------*/
.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.profile-cover-content {
  position: relative;
  z-index: 1;
}

.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: #e7eaf3;
  border-radius: 0.5rem;
}

.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.5rem;
}

.profile-cover-avatar {
  display: flex;
  background-color: #fff;
  padding: 0.75rem 0.75rem;
  margin-top: -1.75rem;
}

.profile-cover-content {
  padding: 1rem 1rem;
}

.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .profile-cover {
    height: 10rem;
  }
  .profile-cover-img-wrapper {
    height: 10rem;
  }
  .profile-cover-img {
    height: 10rem;
  }
}
/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  background-color: #e7eaf3;
  width: 0.5rem;
  height: 12.5rem;
}

/*------------------------------------
  Shapes
------------------------------------*/
.shape-container {
  position: relative;
}

.shape[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.shape {
  position: absolute;
  z-index: -1;
}

.shape-top {
  top: 0;
  left: 0;
  right: 0;
}
.shape-top > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  transform-origin: bottom center;
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.shape-bottom > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  transform-origin: top center;
}

.shape-start {
  top: 0;
  left: 0;
  bottom: 0;
}
.shape-start > svg {
  width: auto;
  height: 100%;
  transform-origin: left center;
}

.shape-end {
  top: 0;
  right: 0;
  bottom: 0;
}
.shape-end > svg {
  width: auto;
  height: 100%;
  transform-origin: right center;
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: calc(1.5rem / -2);
  margin-left: calc(1.5rem / -2);
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-icon-border {
  border: 0.125rem solid rgba(33, 50, 91, 0.1);
}

.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}

.step-text:last-child {
  color: #677788;
  margin-bottom: 0;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-bottom: 2.25rem;
}

.step-item-between .step-item:last-child {
  flex: 0 0 auto;
  width: auto;
}

.step .step-content-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.step .step-content {
  flex: 1;
}

.step-item.collapse:not(.show) {
  display: none;
}

.step-item .step-title-description {
  display: none;
}

.step-item.focus .step-title-description {
  display: block;
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step .step-avatar {
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}
.step .step-avatar::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step-avatar-xs .step-avatar, .step-avatar-xs.step-avatar {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.step-avatar-xs .step-avatar::after, .step-avatar-xs.step-avatar::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}
.step-avatar-xs .step-divider::after {
  left: 0.875rem;
}

.step-avatar-sm .step-avatar, .step-avatar-sm.step-avatar {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}
.step-avatar-sm .step-avatar::after, .step-avatar-sm.step-avatar::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}
.step-avatar-sm .step-divider::after {
  left: 1.25rem;
}

.step-avatar-lg .step-avatar, .step-avatar-lg.step-avatar {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}
.step-avatar-lg .step-avatar::after, .step-avatar-lg.step-avatar::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}
.step-avatar-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Divider
------------------------------------*/
.step-divider {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-size: 0.8125rem;
  font-weight: 600;
}
.step-divider::after {
  position: absolute;
  top: 1.75rem;
  left: 1.5625rem;
  height: calc(100% - 0.25rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-icon::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}
.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: "";
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs .step-icon, .step-icon-xs.step-icon {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}
.step-icon-xs .step-icon::after, .step-icon-xs.step-icon::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}
.step-icon-xs .step-divider::after {
  left: 0.875rem;
}

.step-icon-sm .step-icon, .step-icon-sm.step-icon {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}
.step-icon-sm .step-icon::after, .step-icon-sm.step-icon::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}
.step-icon-sm .step-divider::after {
  left: 1.25rem;
}

.step-icon-lg .step-icon, .step-icon-lg.step-icon {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}
.step-icon-lg .step-icon::after, .step-icon-lg.step-icon::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}
.step-icon-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-sm.step-icon-xs .step-icon::after,
.step-sm .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-sm.step-icon-sm .step-icon::after,
.step-sm .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-sm.step-icon-lg .step-icon::after,
.step-sm .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-md.step-icon-xs .step-icon::after,
.step-md .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-md.step-icon-sm .step-icon::after,
.step-md .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-md.step-icon-lg .step-icon::after,
.step-md .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-lg.step-icon-xs .step-icon::after,
.step-lg .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-lg.step-icon-sm .step-icon::after,
.step-lg .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-lg.step-icon-lg .step-icon::after,
.step-lg .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-xl.step-icon-xs .step-icon::after,
.step-xl .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-xl.step-icon-sm .step-icon::after,
.step-xl .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-xl.step-icon-lg .step-icon::after,
.step-xl .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}
/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-sm.step-centered.step-icon-xs .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-sm.step-centered.step-icon-sm .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-sm.step-centered.step-icon-lg .step-icon::after,
.step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-md.step-centered.step-icon-xs .step-icon::after,
.step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-md.step-centered.step-icon-sm .step-icon::after,
.step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-md.step-centered.step-icon-lg .step-icon::after,
.step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
.step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}
/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}
.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #006C7B;
}
.step .active .step-title,
.step .active.is-valid .step-title {
  color: #006C7B;
}
.step .is-valid .step-icon {
  color: #fff;
  background-color: #006C7B;
}
.step .is-valid .step-title {
  color: #006C7B;
}
.step .is-valid .step-is-valid-icon {
  display: inline-flex;
}
.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}
.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}
.step .is-invalid .step-title {
  color: #ed4c78;
}
.step .is-invalid .step-is-invalid-icon {
  display: inline-flex;
}
.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
.step-icon-primary {
  color: #fff;
  background-color: #006C7B;
}
.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-secondary {
  color: #fff;
  background-color: #71869d;
}
.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}
.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-info {
  color: #fff;
  background-color: #09a5be;
}
.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-warning {
  color: #000;
  background-color: #f5ca99;
}
.step-icon-warning.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}
.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-light {
  color: #000;
  background-color: #f7faff;
}
.step-icon-light.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-dark {
  color: #fff;
  background-color: #21325b;
}
.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-soft-primary {
  color: #006C7B;
  background-color: rgba(0, 108, 123, 0.1);
}
.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #006C7B;
}

.step-icon-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}
.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #71869d;
}

.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

.step-icon-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}
.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #09a5be;
}

.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}

.step-icon-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}
.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f7faff;
}

.step-icon-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}
.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #21325b;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  align-items: center;
}
.step-inline .step-item:last-child .step-title::after {
  display: none;
}
.step-inline .step-title {
  display: inline-block;
}

@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after,
.step-sm.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after,
.step-sm.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after,
.step-sm.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-md.step-inline.step-icon-xs .step-content .step-title::after,
.step-md.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-md.step-inline.step-icon-sm .step-content .step-title::after,
.step-md.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-md.step-inline.step-icon-lg .step-content .step-title::after,
.step-md.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after,
.step-lg.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after,
.step-lg.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after,
.step-lg.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after,
.step-xl.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after,
.step-xl.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after,
.step-xl.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}
/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -2.75rem;
  }
}
@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -2.75rem;
  }
}
/*------------------------------------
  SVG Icon
------------------------------------*/
.svg-icon {
  display: block;
  margin-top: 0.125rem;
}

.svg-icon[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.svg-inline {
  display: inline-block;
}

.svg-icon > svg {
  width: 3.125rem;
  height: 3.125rem;
}

.svg-icon-xs > svg {
  width: 1.75rem;
  height: 1.75rem;
}

.svg-icon-sm > svg {
  width: 2.5rem;
  height: 2.5rem;
}

.svg-icon-lg > svg {
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Toasts
------------------------------------*/
.toast-header .btn-close {
  margin-right: 0;
}

/*------------------------------------
  Tables
------------------------------------*/
.table th {
  font-weight: 400;
}
.table tr {
  color: #21325b;
}
.table thead th {
  color: #1e2022;
  font-weight: 600;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(33, 50, 91, 0.1);
}
.table > :not(caption) > *:last-child > * {
  border-bottom-width: 0;
}
.table .btn {
  white-space: nowrap;
}

.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}

.table-align-middle tbody tr, .table-align-middle th, .table-align-middle td {
  vertical-align: middle;
}

.table-text-center, .table-text-center th, .table-text-center td {
  text-align: center;
}

.table-text-end, .table-text-end th, .table-text-end td {
  text-align: right;
}

.thead-light th {
  background-color: #f8fafd;
}

.table-lg > :not(caption) > * > * {
  padding: 1rem 1.5rem;
}

.table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.table-vertical-border-striped > thead > tr > th:not(:first-child):not(:last-child),
.table-vertical-border-striped > tbody > tr > td:not(:first-child):not(:last-child) {
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Text Highlight
------------------------------------*/
[class*=text-highlight-] {
  background: left 1em/1em 0.2em;
  background-repeat: repeat-x;
}

.text-highlight-primary {
  background-image: linear-gradient(to bottom, rgba(0, 108, 123, 0.5), rgba(0, 108, 123, 0.5));
}

.text-highlight-secondary {
  background-image: linear-gradient(to bottom, rgba(113, 134, 157, 0.5), rgba(113, 134, 157, 0.5));
}

.text-highlight-success {
  background-image: linear-gradient(to bottom, rgba(0, 201, 167, 0.5), rgba(0, 201, 167, 0.5));
}

.text-highlight-info {
  background-image: linear-gradient(to bottom, rgba(9, 165, 190, 0.5), rgba(9, 165, 190, 0.5));
}

.text-highlight-warning {
  background-image: linear-gradient(to bottom, rgba(245, 202, 153, 0.5), rgba(245, 202, 153, 0.5));
}

.text-highlight-danger {
  background-image: linear-gradient(to bottom, rgba(237, 76, 120, 0.5), rgba(237, 76, 120, 0.5));
}

.text-highlight-light {
  background-image: linear-gradient(to bottom, rgba(247, 250, 255, 0.5), rgba(247, 250, 255, 0.5));
}

.text-highlight-dark {
  background-image: linear-gradient(to bottom, rgba(33, 50, 91, 0.5), rgba(33, 50, 91, 0.5));
}

/*------------------------------------
  Video Player
------------------------------------*/
.video-player {
  position: relative;
  display: inline-block;
}

.video-player-inline-btn {
  display: block;
  background-color: #000;
}

.video-player-preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.video-player-played .video-player-preview {
  opacity: 0;
  pointer-events: none;
}

.video-player-btn {
  z-index: 3;
  color: #1e2022;
  transition: transform 0.3s ease-in-out;
}
.video-player-played .video-player-btn {
  animation: videoPlayerButton 0.3s ease-in-out forwards;
  pointer-events: none;
}
.video-player-btn:hover .video-player-icon {
  color: #006C7B;
  transform: scale(1.1);
}

.video-player-icon {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  font-size: 1.25rem;
  border-radius: 50%;
  color: #1e2022;
  background-color: #fff;
  backface-visibility: hidden;
  transform: perspective(1px) translateZ(0);
  transition: 0.3s;
}

.video-player-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}
/*------------------------------------
  Video Bg
------------------------------------*/
.video-bg {
  position: relative;
  min-height: 75vh;
}

.video-bg-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-bg-replacer-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@media (min-width: 768px) {
  .video-bg-replacer-img {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .video-bg-content {
    display: none;
  }
}
.hs-video-bg-video iframe,
.hs-video-bg-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-end {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-y-sm-primary {
  background-image: linear-gradient(to bottom, transparent, rgba(0, 108, 123, 0.075));
}

.gradient-y-gray {
  background-image: linear-gradient(to bottom, transparent, rgba(231, 234, 243, 0.5));
}

.gradient-y-lg-white {
  background-image: linear-gradient(to bottom, transparent, #fff);
}

.gradient-y-three-sm-primary {
  background-image: linear-gradient(transparent, rgba(0, 108, 123, 0.075) 50%, transparent);
}

.gradient-x-three-sm-primary {
  background-image: linear-gradient(to right, rgba(0, 108, 123, 0.03), rgba(9, 165, 190, 0.05) 50%, rgba(245, 202, 153, 0.05));
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*=gradient-x-overlay-],
[class*=gradient-y-overlay-],
[class*=gradient-y-three-overlay-] {
  position: relative;
  z-index: 1;
}
[class*=gradient-x-overlay-]::before,
[class*=gradient-y-overlay-]::before,
[class*=gradient-y-three-overlay-]::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}
[class*=gradient-x-overlay-].card::before,
[class*=gradient-y-overlay-].card::before,
[class*=gradient-y-three-overlay-].card::before {
  border-radius: 0.5rem;
}

.gradient-x-overlay-lg-dark-video::before {
  z-index: 2;
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.95) 0%, rgba(33, 50, 91, 0.95) 100%);
}

.gradient-x-overlay-sm-primary::before {
  background-image: linear-gradient(45deg, transparent, rgba(0, 108, 123, 0.05));
}

.gradient-x-overlay-sm-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.25) 0%, rgba(30, 32, 34, 0.25) 100%);
}

.gradient-x-overlay-lg-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.9) 0%, rgba(33, 50, 91, 0.9) 100%);
}

.gradient-y-overlay-lg-dark::before {
  background-image: linear-gradient(to bottom, transparent 40%, rgba(12, 13, 14, 0.6));
}

.gradient-y-overlay-lg-white::before {
  background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.9));
}

.gradient-y-overlay-sm-gray-900::before {
  background-image: linear-gradient(to bottom, rgba(30, 32, 34, 0.3), rgba(33, 50, 91, 0.2));
}

/*------------------------------------
  Link
------------------------------------*/
.link {
  font-weight: 600;
}

.link-primary:hover, .link-primary:focus {
  color: #004d57;
}

.link-secondary:hover, .link-secondary:focus {
  color: #004d57;
}

.link-success:hover, .link-success:focus {
  color: #004d57;
}

.link-info:hover, .link-info:focus {
  color: #004d57;
}

.link-warning:hover, .link-warning:focus {
  color: #004d57;
}

.link-danger:hover, .link-danger:focus {
  color: #004d57;
}

.link-light:hover, .link-light:focus {
  color: rgba(255, 255, 255, 0.7);
}

.link-dark:hover, .link-dark:focus {
  color: #004d57;
}

.link-sm {
  font-size: 0.875rem;
}

.link-collapse[aria-expanded=false] .link-collapse-default {
  display: inline-block;
}
.link-collapse[aria-expanded=false] .link-collapse-active {
  display: none;
}

.link-collapse[aria-expanded=true] .link-collapse-default {
  display: none;
}
.link-collapse[aria-expanded=true] .link-collapse-active {
  display: inline-block;
}

/*------------------------------------
  Typography
------------------------------------*/
.text-cap {
  display: block;
  color: #1e2022;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-dark {
  color: #1e2022 !important;
}
.text-dark[href]:hover {
  color: #004d57 !important;
}

.link-secondary[href]:hover,
.text-secondary[href]:hover,
.text-muted[href]:hover,
.text-body[href]:hover {
  color: #004d57 !important;
}

a:hover .text-inherit {
  color: #004d57 !important;
}

/*------------------------------------
  Transform
------------------------------------*/
.transform-rotate-n40deg {
  transform: rotate(-40deg);
  transform-origin: 30% 20%;
}

.transform-rotate-n22deg {
  transform: rotate(-22deg);
}

.rotated-3d-left {
  transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
}

.rotated-3d-right {
  transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
}

/*------------------------------------
  Divider
------------------------------------*/
.divider-start {
  display: flex;
  align-items: center;
  color: #8c98a4;
}
.divider-start::after {
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}
.divider-start::after {
  margin-left: 1.5rem;
}

.divider-end {
  display: flex;
  align-items: center;
  color: #8c98a4;
}
.divider-end::before {
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}
.divider-end::before {
  margin-right: 1.5rem;
}

.divider-center {
  display: flex;
  align-items: center;
  color: #8c98a4;
}
.divider-center::before, .divider-center::after {
  flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: "";
}
.divider-center::before {
  margin-right: 1.5rem;
}
.divider-center::after {
  margin-left: 1.5rem;
}

/*------------------------------------
  Nav Scroll Horizontal
------------------------------------*/
.hs-nav-scroller-horizontal {
  position: relative;
}
.hs-nav-scroller-horizontal .nav {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.hs-nav-scroller-horizontal .nav .nav-item {
  white-space: nowrap;
}
.hs-nav-scroller-horizontal .nav .nav-link {
  white-space: nowrap;
}
.hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
  display: none;
}
.hs-nav-scroller-horizontal .nav-tabs {
  padding-bottom: 0.1875rem;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  position: absolute;
  height: 100%;
  z-index: 1;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
  width: 3.125rem;
  color: #677788;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
  color: #004d57;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
  left: 0;
  margin-left: -0.125rem;
  background-image: linear-gradient(to right, #fff 50%, transparent 100%);
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  right: 0;
  margin-right: -0.125rem;
  background-image: linear-gradient(to right, transparent 0%, #fff 50%);
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
  text-align: right;
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  color: rgba(255, 255, 255, 0.7);
}
.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link:hover,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link:hover {
  color: #fff;
}
.hs-nav-scroller-light .hs-nav-scroller-arrow-prev {
  background-image: linear-gradient(to right, #21325b 50%, transparent 100%);
}
.hs-nav-scroller-light .hs-nav-scroller-arrow-next {
  background-image: linear-gradient(to right, transparent 0%, #21325b 50%);
}

.hs-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.hs-nav-scroller-vertical::-webkit-scrollbar {
  width: 0.6125rem;
}
.hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.hs-nav-scroller-unfold {
  position: static;
}

/*------------------------------------
  Dropzone
------------------------------------*/
.dz-dropzone {
  cursor: pointer;
}
.dz-dropzone .dz-message {
  width: 100%;
  text-align: center;
}
.dz-dropzone .dz-details {
  margin-bottom: 1rem;
}
.dz-dropzone .dz-file-preview {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}
.dz-dropzone .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dz-dropzone .dz-filename {
  margin-bottom: 0.25rem;
}
.dz-dropzone .dz-img {
  max-width: 3.125rem;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}
.dz-dropzone .dz-img-inner {
  border-radius: 0.5rem;
}
.dz-dropzone .dz-close-icon,
.dz-dropzone .dz-size {
  color: #677788;
}
.dz-dropzone .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}
.dz-dropzone .dz-size {
  font-size: 80%;
}
.dz-dropzone .dz-file-initials {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  font-weight: 600;
  font-size: 1rem;
  color: #006C7B;
  text-align: center;
  background-color: rgba(0, 108, 123, 0.1);
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}
.dz-dropzone [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}
.dz-dropzone .dz-progress {
  margin-bottom: 1rem;
}
.dz-dropzone .dz-processing .dz-error-mark,
.dz-dropzone .dz-processing .dz-success-mark {
  display: none;
}
.dz-dropzone .dz-processing.dz-error .dz-error-mark,
.dz-dropzone .dz-processing.dz-success .dz-success-mark {
  display: block;
}
.dz-dropzone .dz-processing .dz-error-mark {
  color: #ed4c78;
}
.dz-dropzone .dz-processing .dz-success-mark {
  color: #00c9a7;
}

.dz-dropzone-card {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: flex;
  flex-wrap: wrap;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.fancybox-custom .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}

.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}

.fancybox-custom .fancybox-content {
  background-color: transparent;
}

.fancybox-custom .fancybox-bg {
  background-color: #1e2022;
}

.fancybox-custom .fancybox-button svg {
  margin-bottom: 0;
}

.fancybox-custom .fancybox-progress {
  background-color: #006C7B;
}

.fancybox-blur header,
.fancybox-blur aside,
.fancybox-blur main,
.fancybox-blur footer {
  filter: blur(30px);
}

/*------------------------------------
  Quantity Counter
------------------------------------*/
.quantity-counter {
  min-width: 9rem;
  padding: 0.5rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.quantity-counter-btn.btn > svg {
  width: 1.25rem;
}

.form-control-quantity-counter {
  border-width: 0;
  height: auto;
  padding: 0;
}
.form-control-quantity-counter:focus {
  box-shadow: none;
}

/*------------------------------------
  Quill Editor
------------------------------------*/
.quill-custom .ql-container {
  position: static;
}
.quill-custom .ql-toolbar.ql-snow,
.quill-custom .ql-container.ql-snow {
  border-color: rgba(33, 50, 91, 0.1);
}
.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.quill-custom .ql-formats:first-child {
  padding-left: 0;
}
.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}
.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}
.quill-custom .ql-editor {
  position: relative;
  padding: 0.6125rem 1rem;
}
.quill-custom .ql-editor p {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  color: #1e2022;
}
.quill-custom .ql-editor.ql-blank::before {
  left: 1rem;
  color: #8c98a4;
  font-family: "Inter", sans-serif;
  font-style: normal;
}
.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #677788;
}
.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #677788;
}
.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #006C7B;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #006C7B;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #006C7B;
}
.quill-custom .ql-snow.ql-toolbar button,
.quill-custom .ql-snow .ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem 0.25rem;
}
.quill-custom .ql-snow.ql-toolbar button svg,
.quill-custom .ql-snow .ql-toolbar button svg {
  margin-bottom: 0;
}
.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  padding: 1.5rem 1.5rem;
  border-radius: 0.3125rem;
  transform: translate(-50%, -50%);
}
.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.6125rem 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #006C7B;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  height: auto;
  display: block;
  font-family: "Inter", sans-serif;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text]:focus {
  border-color: rgba(140, 152, 164, 0.25);
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}
.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #006C7B;
  font-size: 1rem;
  line-height: normal;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-action:hover {
  background-color: #004d57;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove::before,
.quill-custom .ql-snow .ql-tooltip a.ql-action::after {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  font-size: 1rem;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #004d57;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Select
------------------------------------*/
.tom-select-custom .input-group-sm > .ts-wrapper .ts-control.has-items,
.tom-select-custom .ts-wrapper.form-select-sm .ts-control.has-items,
.tom-select-custom .ts-wrapper.form-control-sm .ts-control.has-items {
  padding: 0.5rem 3rem 0.5rem 1rem;
}
.tom-select-custom .input-group-lg > .ts-wrapper > .ts-control,
.tom-select-custom .ts-wrapper.form-control-lg .ts-control,
.tom-select-custom .ts-wrapper.form-select-lg .ts-control {
  padding: 0.75rem 3rem 0.75rem 1rem;
}
.tom-select-custom .hs-select-single-multiple .item {
  display: none !important;
}
.tom-select-custom .ts-wrapper.form-select .ts-control,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple {
  padding: 0.6125rem 3rem 0.6125rem 1rem;
}
.tom-select-custom .ts-wrapper.form-select .ts-control .tom-select-custom-hide,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple .tom-select-custom-hide {
  display: none;
}
.tom-select-custom .ts-wrapper.form-select .ts-control:focus,
.tom-select-custom .ts-wrapper.form-select .ts-control .focus,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple:focus,
.tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple .focus {
  box-shadow: none;
}
.tom-select-custom .ts-dropdown,
.tom-select-custom .ts-dropdown.form-control,
.tom-select-custom .ts-dropdown.form-select {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.tom-select-custom .ts-dropdown .create:hover,
.tom-select-custom .ts-dropdown .option:hover,
.tom-select-custom .ts-dropdown .active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.tom-select-custom .ts-dropdown .option,
.tom-select-custom .ts-dropdown .optgroup-header,
.tom-select-custom .ts-dropdown .no-results,
.tom-select-custom .ts-dropdown .create {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}
.tom-select-custom .ts-dropdown .option {
  position: relative;
}
.tom-select-custom .ts-dropdown .option::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='%23006C7B'%3e%3cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  content: "";
  transform: translateY(-50%);
}
.tom-select-custom .ts-dropdown .option.selected::after {
  display: block;
}
.tom-select-custom .ts-control,
.tom-select-custom .ts-wrapper.single .ts-control.input-active {
  cursor: pointer;
}
.tom-select-custom .input-group > .ts-wrapper, .tom-select-custom .input-group > .form-select {
  flex-grow: unset;
}
.tom-select-custom .input-group > .form-select {
  width: auto;
}
.tom-select-custom .ts-wrapper {
  display: inline-block;
}
.tom-select-custom .ts-dropdown .optgroup-header {
  font-weight: bold;
  color: #000;
}
.tom-select-custom .ts-dropdown .optgroup:before {
  display: none;
}
.tom-select-custom .ts-dropdown .optgroup .option {
  padding-left: 1.5rem;
}
.tom-select-custom .ts-wrapper.multi .ts-control > div {
  background-color: #e7eaf3;
  font-size: 0.875rem;
  padding: 0.28rem 0.875rem;
  border-radius: 4px;
}
.tom-select-custom .ts-wrapper.plugin-remove_button .item .remove {
  border-left: none;
  color: #71869d;
}
.tom-select-custom .ts-wrapper.plugin-remove_button .item .remove:hover {
  color: #333;
  background: transparent;
}
.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap {
  padding: 0.4rem;
}
.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}
.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input::placeholder {
  color: #8c98a4;
  opacity: 1;
}
.tom-select-custom .ts-custom-placeholder,
.tom-select-custom .ts-selected-count {
  color: #8c98a4 !important;
}

.tom-select-custom-with-tags .ts-wrapper.form-select .ts-control,
.tom-select-custom-with-tags .tom-select-custom .ts-wrapper.multi .ts-control.has-items.hs-select-single-multiple {
  padding: calc(0.375rem - 1px - 0px) 0.75rem calc(0.375rem - 1px - 4px - 0px);
}
.tom-select-custom-with-tags .ts-wrapper.multi .ts-control > .item {
  color: #1e2022;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Swiper
------------------------------------*/
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: #677788;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.4375rem 0rem rgba(140, 152, 164, 0.4);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  width: 1rem;
  height: 1rem;
  font-family: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  content: "";
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  box-shadow: none;
}

.swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.swiper-button-next:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006C7B'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.swiper-button-prev:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006C7B'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-button-next-soft-white,
.swiper-button-prev-soft-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.swiper-button-next-soft-white:hover,
.swiper-button-prev-soft-white:hover {
  background-color: #006C7B;
}

.swiper-button-next-soft-white:after, .swiper-button-next-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev-soft-white:after, .swiper-button-prev-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-pagination {
  position: static;
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.swiper-pagination.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 0;
  transform: translateX(0%);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 0%;
  transform: translateX(0%);
}

.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  transform: translateX(0%);
}
.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.25rem;
}

.swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid transparent;
  background-color: transparent;
  transition: 0.2s;
}
.swiper-pagination-bullet::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  content: "";
}
.swiper-pagination-bullet:hover {
  border-color: rgba(0, 108, 123, 0.5);
}
.swiper-pagination-bullet:hover::before {
  background-color: rgba(0, 108, 123, 0.5);
}

.swiper-pagination-bullet-active, .swiper-pagination-bullet-active:hover {
  border-color: #006C7B;
}
.swiper-pagination-bullet-active::before, .swiper-pagination-bullet-active:hover::before {
  background-color: #006C7B;
}

.swiper-pagination-light .swiper-pagination-bullet::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-light .swiper-pagination-bullet:hover {
  border-color: #fff;
}
.swiper-pagination-light .swiper-pagination-bullet:hover::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-light .swiper-pagination-bullet-active, .swiper-pagination-light .swiper-pagination-bullet-active:hover {
  border-color: #fff;
}
.swiper-pagination-light .swiper-pagination-bullet-active::before, .swiper-pagination-light .swiper-pagination-bullet-active:hover::before {
  background-color: #fff;
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-body {
  position: relative;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: rgba(0, 108, 123, 0.1);
}

.swiper-pagination-progress-body-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #006C7B;
  transition: none;
}

.swiper-slide-thumb-active .swiper-pagination-progress-body-helper {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress-light .swiper-pagination-progress-body {
  background-color: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress-light .swiper-pagination-progress-body-helper {
  background-color: #fff;
}

.swiper-pagination-progressbar {
  background-color: rgba(0, 108, 123, 0.1);
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #006C7B;
}

.swiper-pagination-fraction {
  display: block;
  letter-spacing: 0.125rem;
}
.swiper-pagination-fraction .swiper-pagination-current {
  font-size: 4rem;
  line-height: 4rem;
}

.swiper-thumbs {
  box-sizing: border-box;
}

.swiper-thumbs .swiper-slide {
  cursor: pointer;
  opacity: 0.4;
}

.swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-equal-height .swiper-wrapper {
  display: flex;
}
.swiper-equal-height .swiper-slide {
  display: flex;
  height: auto;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  position: absolute;
  width: auto !important;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
}
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0.25rem 0;
}

.swiper-horizontal > .swiper-pagination-middle-y-end {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: auto;
  transform: translateY(-50%);
}

.swiper-center-mode-end {
  margin-right: calc(-1px - (100vw - 100%) / 2 + 15px) !important;
}

.swiper-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper-thumb-progress-avatar {
  position: relative;
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 50%;
}

.swiper-thumb-progress-avatar-img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #006C7B;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #006C7B;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

@keyframes swiperThumbProgressDash {
  from {
    stroke-dasharray: 0 477;
  }
  to {
    stroke-dasharray: 477 477;
  }
}
/*------------------------------------
  Leaflet
------------------------------------*/
.leaflet {
  min-height: 30rem;
  height: 100%;
  z-index: 0;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
}

.leaflet-popup {
  margin-bottom: 2.5rem;
}

/*------------------------------------
  NoUiSlider
------------------------------------*/
.range-slider.range-slider-pips {
  min-height: 3rem;
}
.range-slider .noUi-target {
  border: none;
  box-shadow: none;
  background: #e7eaf3;
  margin-top: 1.25rem;
}
.range-slider .noUi-connect {
  background-color: #006C7B;
}
.range-slider .noUi-horizontal {
  height: 0.3125rem;
}
.range-slider .noUi-horizontal .noUi-handle {
  top: -0.75rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}
.range-slider .noUi-handle {
  border: none;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  border-radius: 50%;
}
.range-slider .noUi-handle:before, .range-slider .noUi-handle:after {
  display: none;
}
.range-slider .noUi-horizontal .noUi-tooltip {
  bottom: 130%;
}
.range-slider .noUi-tooltip {
  border: none;
  font-size: 0.8125rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  min-width: 2.5rem;
  padding: 0.5rem 0.5rem;
}
.range-slider .noUi-tooltip::after {
  position: absolute;
  display: block;
  bottom: -0.625rem;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -0.3125rem;
  overflow: hidden;
  border: 0.3125rem solid transparent;
  border-top-color: #fff;
  content: "";
}
.range-slider .noUi-marker {
  width: 0.0625rem;
  background: #bdc5d1;
}
.range-slider .noUi-pips {
  color: #677788;
}
.range-slider .noUi-value-large {
  color: #677788;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/*------------------------------------
  File for your custom SCSS style
------------------------------------*/